//@ts-nocheck
"use client"
import React, { FC, useEffect, useState } from "react";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";
import convert from "htmr";
import { helpers } from "../../../../services/_helpers/Helpers";
import { ImageSrc } from "../../../elements/ImageSrc";
import DynComponents from "../../../../src/components/DynComponents";
import { api } from "../../../../services/api/Api";

interface IProps {
    link: any;
    publish: boolean;
}

const SliderCollection: FC<IProps> = ({ link, publish }) => {
    const [slides, setSlides] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);


    useEffect(() => {
        async function fetchData() {
            const query = {
                filter: {
                    _id: link._id
                }
            };
            const items = await api.cms.get("slides", query, true);
            if (items) {
                setData(items);
                setSlides(items.slider);
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);

    // Функция для получения всех стилей элемента и вложенных элементов
    // const getAllStyles = (element: HTMLElement): any => {
    //     const styles = {};
    //     const computedStyle = window.getComputedStyle(element);

    //     // Получение стилей текущего элемента
    //     for (let key of computedStyle) {
    //         styles[key] = computedStyle.getPropertyValue(key);
    //     }

    //     // Рекурсивно обходим дочерние элементы
    //     Array.from(element.children).forEach((child: HTMLElement) => {
    //         styles[child.tagName] = getAllStyles(child as HTMLElement);
    //     });

    //     return styles;
    // };

    const save = async (index) => {
        // Получение стилей из <style id="wyp-styles-area">
        const styleElement = document.getElementById("wyp-styles-area");
        if (styleElement) {
            const styles = styleElement.innerHTML;
            // Копируем текущий массив slides
            const updatedSlides = [...slides];

            // Добавляем или обновляем стиль в объекте слайда
            updatedSlides[index] = {
                ...updatedSlides[index],
                style: styles, // Добавляем новый ключ `style`
            };
            console.log("Стили из <style>: ", styles);
            const query = {
                ...data,
                slider: updatedSlides,
            };
            console.log("query", query);
            await api.cms.save('slides', query);
            // Отправка данных на сервер или другое использование
        } else {
            console.error("Элемент <style> с id='wyp-styles-area' не найден.");
        }
    };

    if (!Array.isArray(slides) || isLoading) return null;

    return (
        <Row className="justify-content-center">
            <Col xs={12}>
                <Carousel
                    fade={true}
                    indicators={false}
                    prevLabel=""
                    nextLabel=""
                    interval={null}
                    touch={true}
                >
                    {slides.map((slide, idx) => {
                        const image = helpers.image.getUrlImage(slide?.image, false, false);
                        return (
                            <Carousel.Item
                                key={`slider-${link._id}-${idx}`}
                                id={`slider-${link._id}-${idx}`}
                                className="position-relative"
                                style={{
                                    backgroundSize: slide.bgSize === "custom" ? slide.bgScale : slide.bgSize,
                                    display: "block",
                                    width: "100%",
                                    height: "550px",
                                    backgroundImage: `url(${image.webP})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: slide.bgPosition
                                }}
                            >
                                {slide.style && <style className="slider-style">{slide.style}</style>}
                                {Array.isArray(slide.layout) && slide.layout.length > 0 && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            ...(slide.width && { width: slide.width }),
                                            ...(slide.height && { height: slide.height }),
                                            ...(slide.padding && { padding: slide.padding }),
                                            ...(slide.left && { left: slide.left }),
                                            ...(slide.top && { top: slide.top }),
                                            ...(slide.fullBackground && { backgroundColor: slide.fullBackground })
                                        }}
                                    >
                                        <DynComponents save={true} components={slide.layout} />
                                    </div>
                                )}
                                <button className="btn btn-success"
                                    style={{
                                        position: "fixed", top: "0px", left: "50%", zIndex: 9999
                                    }}
                                    onClick={() => save(idx)}>Сохранить</button>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </Col>
        </Row>
    );
};

export default SliderCollection;
