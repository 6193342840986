"use client"
import React, { useState } from 'react';
import convert from "htmr";
import { MDBCollapse } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

const Text = ({ text = '', params = null, className = 'py-3', idx = 0 }) => {
    const [collapseID, setCollapseID] = useState("");
    const [collapseStatus, setCollapseStatus] = useState(false);

    const isCollapsible = params === 'collapse';
    let cutText = "";
    let collapseText = "";

    if (isCollapsible) {
        const splitText = text.split('collapse');
        cutText = splitText[0] || '';
        collapseText = splitText[1] || '';
    }

    const toggleCollapse = (id) => {
        setCollapseID((prev) => (prev !== id ? id : ""));
    };

    const handleCollapseStatus = () => {
        setCollapseStatus((prev) => !prev);
    };

    const renderHTML = (html) => {
        return typeof html === "string" && html.trim() !== "" ? convert(html) : null;
    };

    return isCollapsible ? (
        <div className={`${className}`} id={`txt-${idx}`}>
            {renderHTML(cutText)}
            <MDBCollapse MDBCollapse
                id="basicCollapse"
                onOpened={handleCollapseStatus}
                onClosed={handleCollapseStatus}
                isOpen={collapseID === "basicCollapse"}
            >
                {renderHTML(collapseText)}
            </MDBCollapse>
            <a
                onClick={() => toggleCollapse("basicCollapse")}
                style={{ marginBottom: "1rem", cursor: "pointer" }}
            >
                <FontAwesomeIcon icon={collapseStatus ? faArrowUp : faArrowDown} /> Подробнее
            </a>
        </div >
    ) : (
        <div className={className || "py-3"} id={`txt-${idx}`}>
            {renderHTML(text)}
        </div>
    );
};

export default Text;


// import React, { Component } from 'react';
// import convert from "htmr";
// import { MDBTypography, MDBBtn, MDBBox, MDBCollapse } from 'mdbreact';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

// //import Image from "../../Image";


// // const transform = {
// //   img: node => <Image {...node} />,
// //   a: node => <NavLink to={node.href} {...node} />,
// // };

// export default class Text extends Component {
//     constructor(props) {
//         super(props);
//         if (props.params && props.params == 'collapse') {
//             let splitText = this.props.text.split('collapse');
//             this.cut_text = splitText[0];
//             this.collapse_text = splitText[1];

//             // let count_text = text.length;
//             // let view_text = Math.round(count_text/3);
//             // this.cut_text = text.substr(0, view_text)
//             // this.collapse_text = text.substr(view_text);
//             this.collapse = true;
//         } else {
//             this.collapse = false;
//         }
//         this.state = {
//             collapseID: "",
//             collapse: this.collapse,
//             collapseStatus: false,
//             collapseButton: "Подробнее",
//             text: props.text ? props.text : '',
//             params: props.params ? props.params : null,
//             className: props.class ? props.class : '',
//         };
//     }



//     toggleCollapse = collapseID => () => {
//         this.setState(prevState => ({
//             collapseID: prevState.collapseID !== collapseID ? collapseID : ""
//         }));
//     }
//     collapseStatus = () => {
//         this.setState(prevState => ({
//             collapseStatus: !prevState.collapseStatus
//         }));
//     }
//     render() {
//         if (this.state.collapse) {
//             return (
//                 <div className={`py-3 ${this.state.className}`}>
//                     {this.cut_text && convert(this.cut_text)}
//                     <MDBCollapse
//                         id="basicCollapse"
//                         onOpened={this.collapseStatus}
//                         onClosed={this.collapseStatus}
//                         isOpen={this.state.collapseID}
//                     >
//                         {this.collapse_text && convert(this.collapse_text)}
//                     </MDBCollapse>
//                     <a
//                         color="primary"
//                         onClick={this.toggleCollapse("basicCollapse")}
//                         style={{ marginBottom: "1rem" }}
//                     >
//                         <FontAwesomeIcon icon={this.state.collapseStatus ? faArrowUp : faArrowDown} /> Подробнее
//                     </a>
//                 </div>
//             );
//         } else {
//             return (

//                 <div className={this.state.className ? this.state.className : "py-3"} >
//                     {/*{text && convert(text, { transform })}*/}
//                     {this.state.text && convert(this.state.text)}
//                 </div>
//             );
//         }
//     }
// }