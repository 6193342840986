"use client"
import React, { Component } from 'react';
import Utils from "../../../../utils/all";
import classes from "./ItemGrid.module.css";

export default class ItemGrid extends React.Component {
    constructor(props) {
        super(props);
        const id = Utils.randStr(5);

        this.titleBlockRef = React.createRef();

        this.state = {
            title: props.title ? props.title : null,
            link: props.link ? props.link : null,
            image: props.image ? props.image : null,
            isLoad: false,
            id: id,
            boxStyle: {}
        };

    }
    componentDidMount() {
        let titleBoxHeight = this.titleBlockRef ? this.titleBlockRef.current.offsetHeight / 2 : null;
        if (titleBoxHeight) {
            this.setState({
                boxStyle: { top: `calc(50% - ${titleBoxHeight}px)` }
            });
        }
        // let titleBox = document.getElementById(this.state.id).getElementsByClassName("comp-title")[0];
        // let titleBoxHeight = titleBox ? titleBox.offsetHeight/2 : null;
        // if(titleBoxHeight) {
        //     this.setState({
        //         boxStyle:{top: `calc(50% - ${titleBoxHeight}px)`}
        //     });
        // }
    }
    render() {
        const { title, link, id, image } = this.state;
        if (!Utils.isEmpty(title) && !Utils.isEmpty(link) && !Utils.isEmpty(image)) {
            return (
                <div className={classes.Post} test-id={id}>
                    <a href={link}>
                        <div className={classes.overlay}></div>
                        <img
                            className={classes.img}
                            src={Utils.getImageStyleUrl("square800", image)}
                        />
                        <div
                            ref={this.titleBlockRef}
                            className={`comp-title ${classes.body_post}`}
                            style={this.state.boxStyle}
                        >
                            <div className={classes.post_content}>

                                <h4>{title}</h4>
                            </div>
                        </div>
                    </a>
                </div>
            );
        } else {
            return null;
        }

    }
}

