"use client"
import { Row } from "react-bootstrap";

const BtHeading = (props) => {
    const Tag = `${props.tag}`;
    if (props.divider) {
        return (
            <div className="container">
                <Row className={props.class}>
                    <div className="left-title-hr col"></div>
                    <div className={"title-hr"}>
                        <Tag>{props.text}</Tag>
                    </div>
                    <div className="right-title-hr col"></div>
                </Row>
            </div>
        );
    }
    return (
        <div>
            <Tag className={`title-main ${props.class}`}>{props.text}</Tag>
        </div>
    );
};
export default BtHeading;