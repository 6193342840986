"use client"
import React from "react";
const Heading = ({ align = 'center', className = '', tag = 'h1', text = '', styleHeader = null }) => {
    const Tag = `${tag}`;
    let classHead = [];
    if (className) {
        classHead.push(className)
    } else {
        classHead.push('my-4');
    }
    classHead.push(`text-${align}`);
    switch (styleHeader) {
        case 'box': {
            classHead.push('h-box');
            return (
                <div suppressHydrationWarning={true} className="text-center my-4">
                    <Tag className={classHead.join(' ')}>
                        <span className="after" style={{
                            top: `calc(${Math.floor(Math.random() * 15) - 9}px)`,
                            left: `calc(${Math.floor(Math.random() * 15) - 9}px)`
                        }} />
                        <span className="after" style={{
                            top: `calc(${Math.floor(Math.random() * 15) - 9}px)`,
                            left: `calc(${Math.floor(Math.random() * 15) - 9}px)`
                        }} />
                        <span className="after" style={{
                            top: `calc(${Math.floor(Math.random() * 15) - 9}px)`,
                            left: `calc(${Math.floor(Math.random() * 15) - 9}px)`
                        }} />
                        {text}
                    </Tag>
                </div>
            );
        }
            break;
        default:
            return <Tag suppressHydrationWarning={true} className={classHead.join(' ')}>{text}</Tag>;
            break;
    }
};
export default Heading;