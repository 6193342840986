import React from "react";
import convert from "htmr";

//import Image from "../../Image";


// const transform = {
//   img: node => <Image {...node} />,
//   a: node => <NavLink to={node.href} {...node} />,
// };
const Html =(props) => {
    const {
        name,
        html,
        ...other
    } = props;
    return(
    <React.Fragment>
      {/*{text && convert(text, { transform })}*/}
      {html && convert(html)}
    </React.Fragment>
    );
}
export default Html;