"use client"
import React, { Component } from 'react';
import { Row, Col } from "react-bootstrap";
import Conf from "../../../../config";
import PropTypes from 'prop-types';
import { postApi } from '../../../../server/lib/api';
import ArrayUtil from "../../../../utils/array";

export default class CollectionLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            all: props.all,
            seolinks: [],
            isLoad: false
        };
    }
    async componentDidMount() {
        if (this.state.all) {
            let result = await postApi(`${Conf.siteUrl}/api/seopages/all`)
                .then(res => {
                    let items = res.data;
                    let seolinks = ArrayUtil.splitTo(items, 3);
                    this.setState(
                        {
                            seolinks: seolinks,
                            isLoad: true
                        }
                    );
                })
                .catch(e => {
                });

        }
    }
    render() {
        if (this.state.isLoad) {
            const title = this.state.title ? <h2 className="h2 text-center">{this.state.title}</h2> : "";
            return (
                <Row>
                    {this.state.seolinks.map((seolink, index) => {
                        return (
                            <Col md="4" key={"citys" + index}>
                                <ul className="link-list">
                                    {seolink.map((item, idx) => {
                                        return (
                                            <li key={"seopage" + idx}>
                                                <a href={"/info/" + item.title_slug}>
                                                    {item.title}
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Col>
                        )
                    })}
                </Row>
            );

        } else {
            return (<></>);
        }
    }
}

CollectionLink.propTypes = {
    name: PropTypes.any.isRequired,
    list: PropTypes.array,
    title: PropTypes.string,
};

CollectionLink.defaultProps = {
    name: "hotels",
    list: []
}


