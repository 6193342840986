//@ts-nocheck
"use client"
import React, { FC, useEffect, useState } from "react";
import Link from 'next/link';
import { ReactSVG } from 'react-svg'
import {
    Navbar,
    Nav
} from "react-bootstrap";
import classes from "./CustomMenu.module.css";
import { api } from "../../../../services/api/Api";
import { useLandingContext } from "../../../../context/LandingProvider";


interface IProps {
    logo: any
    menu: any
};

const CustomMenu: FC<IProps> = ({ logo, menu }) => {
    const { landing } = useLandingContext();
    const [collapse, setCollapse] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [navFixed, setNavFixed] = useState(false);

    const { theme } = landing;

    useEffect(() => {
        if (!Array.isArray(menu)) return null;
        const parsedMenu = menu.map((item, idx) => {
            if (item.urlCustom && item.urlCustom.link && item.urlCustom.title) {
                return (
                    <Nav.Link
                        className={`${classes.link} fw-bold`}
                        key={`mainMenu-${idx}`}
                        href={item?.urlCustom?.link}
                    >
                        {item?.urlCustom?.title}
                    </Nav.Link>
                )
            }

        })
        setData(parsedMenu);
        async function fetchData() {
            const ids = theme.menu && Array.isArray(theme.menu) ? theme.menu.map(menu => menu._id) : [];
            //const indexedIds = theme.menu && Array.isArray(theme.menu) ? theme.menu.map((id, index) => ({ id, index })) : [];
            let query = {
                filter: { _id: { $in: ids }, publish: true },
                fields: { title: 1, title_slug: 1, url: 1, type: 1 }
            };
            const result = await api.cms.get("pages", query);
            const sortedResult = ids.map(item => result.find(resultItem => resultItem._id == item));
            if (sortedResult) {
                setData(sortedResult);
                setIsLoading(false);
            }
        }
        setIsLoading(false);
        // fetchData();
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const mainBanner = document.getElementById('mainBanner');
            if (mainBanner && mainBanner.offsetTop + mainBanner.offsetHeight < window.scrollY) {
                setNavFixed(true);
            } else {
                setNavFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    if (isLoading) return null;
    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            className={`${classes.fxMenu} ${navFixed ? 'position-fixed top-0 bg-info' : 'position-relative'} w-100 z-4`}
        // style={mobile ? { padding: ".5rem 1rem", backgroundColor: `${theme.mainColor}` } : null}
        //className={"d-lg-block d-xl-block"}
        >
            <Navbar.Brand className="text-light d-lg-none" href="#home">Меню</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="navbar-nav justify-content-around" style={{ margin: "0 auto" }}>
                    <Nav.Link href="/" className={`${classes.link} fw-bold`}>Главная</Nav.Link>
                    {Array.isArray(data) && data.map((link, index) => {
                        return link
                    })}
                    {/* {Array.isArray(data) && data.map((link, index) => {
                        if (!link || !link.title_slug) return null;
                        let slug = link.title_slug;
                        let url = link.url;
                        switch (link.type) {
                            case 'rooms':
                                slug = 'rooms';
                                break;
                            case 'services':
                                slug = 'services';
                                break;
                            case 'therapy':
                                slug = 'therapy';
                                break;
                        }
                        return (
                            <Link
                                key={`mainMenu-${index}`}
                                href={`/${url ? url : slug}`}
                                as={`/${url ? url : slug}`}
                                passHref
                            >
                                <Nav.Link href={`/${url ? url : slug}`} className={classes.link}>{link.title}</Nav.Link>
                            </Link>
                        )
                    })} */}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default CustomMenu;