"use client";
import React, { FC, useEffect, useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import Select, { components, InputProps } from "react-select";
import { Col, Row, Button } from "react-bootstrap";
import classes from "./GuestsForm.module.css";
import Utils from "../../../../utils/all";
import { helpers } from "../../../../services/_helpers/Helpers";
import { useLandingContext } from "../../../../context/LandingProvider";

const Input = (props: InputProps) => {
  if (props.isHidden) {
    return <components.Input {...props} />;
  }
  return (
    <div style={{ border: `20px dotted #000` }}>
      <components.Input {...props} />
    </div>
  );
};

interface IProps {
  viewDialog?: boolean; // Используем `boolean` вместо `Boolean` (рекомендовано TypeScript)
  vertical?: boolean;
  initial?: {
    adults?: string;
    kids?: string[];
  };
}

const GuestsForm: FC<IProps> = ({
  viewDialog = false,
  initial = { adults: "1", kids: [] },
}) => {
  const { theme } = useLandingContext();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [adults, setAdults] = useState<number>(parseInt(initial.adults || "1"));
  const [kids, setKids] = useState<string[]>(initial.kids || []);
  const [agesList, setAgesList] = useState(helpers.data.agesList);
  const [showDialog, setShowDialog] = useState<boolean>(viewDialog);
  const [showAge, setShowAge] = useState<boolean>(false);
  const [elemId, setElemId] = useState<string | null>(null);

  const decrease = () => {
    if (adults > 1) setAdults((prev) => prev - 1);
  };

  const increase = () => {
    setAdults((prev) => prev + 1);
  };

  const addChildren = (selected: { value: string }) => {
    setKids((prev) => [...prev, selected.value]);
    setShowAge(false);
  };

  const deleteChildren = (index: number) => {
    setKids((prev) => {
      const newKids = [...prev];
      newKids.splice(index, 1);
      return newKids;
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setShowDialog(false);
    }
  };

  useEffect(() => {
    setElemId(`widget-${Utils.randStr(9)}`);
  }, []);

  useEffect(() => {
    if (!viewDialog) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [viewDialog]);

  const buttonColor = theme?.mainColor || "#0f8195";

  return (
    <div
      suppressHydrationWarning
      className={`text-center ${viewDialog ? `${classes.wrapper} col-auto w-100` : classes.wrapper}`}
      ref={wrapperRef}
    >
      <label>Гости</label>
      {!viewDialog && (
        <div onClick={() => setShowDialog(!showDialog)}>
          <span className={classes.adult}>
            <span className={classes.text}> x {adults}</span>
          </span>
          <span className={classes.childrens}>
            <span className={classes.text}> x {kids.length}</span>
          </span>
        </div>
      )}

      {showDialog && (
        <div
          className={`${viewDialog ? classes.guestFormMobile : classes.guestForm}`}
          style={{ backgroundColor: theme.mainColor }}
        >
          <Row className="mb-2">
            {/* @ts-ignore */}
            <div className="col-12">
              <div className={`${classes.quantityWrapper} w-100`}>
                <div className={`${classes.quantityBox} float-left`}>
                  <ReactSVG
                    src="/icons/fontAwesome/minus-circle.svg"
                    onClick={decrease}
                    beforeInjection={(svg) => {
                      svg.setAttribute("style", `width: 25px; height: 25px; fill: ${buttonColor}`);
                    }}
                  />
                </div>
                <span className={classes.quantityValue}>{`${adults} взрослых`}</span>
                <div className={`${classes.quantityBox} float-right`}>
                  <ReactSVG
                    src="/icons/fontAwesome/plus-circle.svg"
                    onClick={increase}
                    beforeInjection={(svg) => {
                      svg.setAttribute("style", `width: 25px; height: 25px; fill: ${buttonColor}`);
                    }}
                  />
                </div>
              </div>
            </div>
          </Row>
          {kids.map((age, idx) => (
            <Row className="mb-2" key={`age-${idx}`}>
              {/* @ts-ignore */}
              <div className="col-12">
                <div className={classes.quantityWrapper}>
                  <span className={classes.quantityValue}>Ребенок, {age}</span>
                  <span className={classes.deleteChild}>
                    <ReactSVG
                      src="/icons/fontAwesome/times-circle.svg"
                      onClick={() => deleteChildren(idx)}
                      beforeInjection={(svg) => {
                        svg.setAttribute("style", "width: 25px; height: 25px; fill: #dc3545");
                      }}
                    />
                  </span>
                </div>
              </div>
            </Row>
          ))}
          <Row>
            {/* @ts-ignore */}
            <div className="col-12">
              {/* @ts-ignore */}
              <Button
                variant="light"
                className={`${classes.quantityWrapper} w-100`}
                onClick={() => setShowAge(true)}
              >
                <span className={classes.quantityValue}>Добавить ребенка</span>
                <ReactSVG
                  src="/icons/fontAwesome/plus-circle.svg"
                  beforeInjection={(svg) => {
                    svg.setAttribute("style", `width: 25px; height: 25px; fill: ${buttonColor}`);
                  }}
                />
              </Button>
            </div>
          </Row>
          {showAge && (
            <Row>
              {/* @ts-ignore */}
              <div className="col-12">
                <Select
                  onChange={(select) => addChildren(select as { value: string })}
                  options={agesList}
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      padding: "3px 12px",
                      fontSize: "15px",
                    }),
                    control: (base) => ({
                      ...base,
                      minWidth: "100%",
                    }),
                    menuList: (base) => ({
                      ...base,
                      maxHeight: "200px",
                      overflowY: "scroll",
                    }),
                  }}
                  menuIsOpen
                />
              </div>
            </Row>
          )}
        </div>
      )}
      <input type="hidden" name="adults" value={adults.toString()} />
      <input type="hidden" name="kids" value={kids.join(",")} />
    </div>
  );
};

export default GuestsForm;

// "use client"
// import React, { FC, useEffect, useState, useRef } from "react";
// import { useRouter } from 'next/navigation';
// import { ReactSVG } from "react-svg";
// import Select, { components, InputProps } from "react-select";
// import { Col, Row, Button } from "react-bootstrap";
// import classes from "./GuestsForm.module.css";
// import Utils from "../../../../utils/all";
// import { helpers } from "../../../../services/_helpers/Helpers";
// import { useLandingContext } from "../../../../context/LandingProvider";

// const Input = (props: InputProps) => {
//   if (props.isHidden) {
//     return <components.Input {...props} />;
//   }
//   return (
//     <div style={{ border: `20px dotted #000` }}>
//       <components.Input {...props} />
//     </div>
//   );
// };

// interface IProps {
//   viewDialog?: Boolean;
//   vertical?: Boolean;
//   initial?: {
//     adults?: string;
//     kids?: string[];
//   };
// }

// const GuestsForm: FC<IProps> = ({
//   viewDialog = false,
//   initial = { adults: "1", kids: [] },
// }) => {
//   const { theme } = useLandingContext();
//   const wrapperRef = useRef(null);
//   const [adults, setAdults] = useState<number>(parseInt(initial.adults || "1"));
//   const [kids, setKids] = useState<string[]>(initial.kids);
//   const [agesList, setAgesList] = useState(helpers.data.agesList);
//   const [showDialog, setShowDialog] = useState(viewDialog ? true : false);
//   const [showAge, setShowAge] = useState(false);
//   const [elemId, setElemId] = useState(null);
//   const [isLoading, setIsLoading] = useState<boolean>(true);

//   const decrease = async (e) => {
//     adults === 1 ? false : setAdults((prevAdults) => prevAdults - 1);
//   };

//   const increase = async (e) => {
//     e.preventDefault();
//     setAdults((prevAdults) => prevAdults + 1);
//   };

//   const addChildren = (selected) => {
//     setKids((prevState) => [...prevState, selected.value]);
//     setShowAge(false);
//     // this.setState((prevState) => {
//     //   return {
//     //     ...prevState,
//     //     childrens: parseInt(prevState.childrens) + 1,
//     //     ages: [...prevState.ages, selected.value],
//     //     showAge: !prevState.showAge,
//     //   };
//     // });
//   };
//   const deleteChildren = (index) => {
//     setKids((prevState) => {
//       const newKids = [...prevState];
//       newKids.splice(index, 1);
//       return newKids;
//     });
//   };

//   const checkHandleClickOutside = (event) => {
//     if (
//       wrapperRef &&
//       //showDialog &&
//       !wrapperRef?.current?.contains(event.target)
//     ) {
//       setShowDialog(false);
//     }
//   };

//   useEffect(() => {
//     setElemId(`widget-${Utils.randStr(9)}`);
//   }, []);
//   useEffect(() => {
//     if (viewDialog) return () => { };
//     if (elemId && wrapperRef.current) {
//       document.addEventListener("mousedown", checkHandleClickOutside);
//     }

//     // Clean up the event listener on unmount
//     return () => {
//       if (elemId && wrapperRef.current) {
//         document.removeEventListener("mousedown", checkHandleClickOutside);
//       }
//     };
//   }, [showDialog]);

//   // useEffect(() => {
//   //   const { kids, adults } = router.query;
//   //   if (kids) {
//   //     if (Array.isArray(kids)) {
//   //       setKids(kids);
//   //     } else {
//   //       setKids([kids]);
//   //     }
//   //   }
//   //   if (adults && !Array.isArray(adults)) {
//   //     setAdults(parseInt(adults));
//   //   }
//   //   setIsLoading(false);
//   // }, [router.query]);
//   const buttonColor = theme?.mainColor
//     ? theme.mainColor
//     : "#0f8195";

//   return (
//     <Col
//       suppressHydrationWarning
//       xs="auto"
//       className={`text-center ${viewDialog ? classes.wrapper + " w-100" : classes.wrapper
//         }`}
//       ref={wrapperRef}
//     //id={this.state.elemId}
//     >
//       <label>Гости</label>
//       {!viewDialog && (
//         <div onClick={() => setShowDialog(!showDialog)}>
//           <span className={classes.adult}>
//             <span className={classes.text}> x {adults}</span>
//           </span>
//           <span className={classes.childrens}>
//             <span className={classes.text}> x {kids.length}</span>
//           </span>
//         </div>
//       )}

//       {showDialog && (
//         <div
//           className={`${viewDialog ? classes.guestFormMobile : classes.guestForm
//             }`}
//           style={{ backgroundColor: theme.mainColor }}
//         >
//           <Row className="mb-2">
//             <Col xs={12}>
//               <div className={`${classes.quantityWrapper} w-100`}>
//                 <div className={`${classes.quantityBox} float-left`}>
//                   <ReactSVG
//                     src="/icons/fontAwesome/minus-circle.svg"
//                     onClick={decrease}
//                     beforeInjection={(svg) => {
//                       svg.setAttribute(
//                         "style",
//                         `width: 25px; height: 25px; fill: ${buttonColor}`
//                       );
//                     }}
//                   />
//                 </div>
//                 <span
//                   className={classes.quantityValue}
//                 >{`${adults} взрослых`}</span>
//                 <div className={`${classes.quantityBox} float-right`}>
//                   <ReactSVG
//                     src="/icons/fontAwesome/plus-circle.svg"
//                     onClick={increase}
//                     beforeInjection={(svg) => {
//                       svg.setAttribute(
//                         "style",
//                         `width: 25px; height: 25px; fill: ${buttonColor}`
//                       );
//                     }}
//                   />
//                 </div>
//               </div>
//             </Col>
//           </Row>
//           {Array.isArray(kids) &&
//             kids.length > 0 &&
//             kids.map((age, idx) => {
//               let index = agesList.findIndex((i) => i.value === age);
//               return (
//                 <Row className="mb-2">
//                   <Col xs={12} key={`age-${idx}`}>
//                     <div className={classes.quantityWrapper}>
//                       <span className={classes.quantityValue}>
//                         Ребенок, {agesList[index].label}
//                       </span>
//                       <span className={classes.deleteChild}>
//                         <ReactSVG
//                           src="/icons/fontAwesome/times-circle.svg"
//                           onClick={(e) => deleteChildren(idx)}
//                           beforeInjection={(svg) => {
//                             svg.setAttribute(
//                               "style",
//                               `width: 25px; height: 25px; fill: #dc3545`
//                             );
//                           }}
//                         />
//                       </span>
//                     </div>
//                   </Col>
//                 </Row>
//               );
//             })}
//           <Row>
//             <Col xs={12}>
//               <Button
//                 variant="light"
//                 className={`${classes.quantityWrapper} w-100`}
//               >
//                 <span className={classes.quantityValue}>Добавить ребенка</span>
//                 <ReactSVG
//                   src="/icons/fontAwesome/plus-circle.svg"
//                   onClick={() => setShowAge(true)}
//                   beforeInjection={(svg) => {
//                     svg.setAttribute(
//                       "style",
//                       `width: 25px; height: 25px; fill: ${buttonColor}`
//                     );
//                   }}
//                 />
//               </Button>
//             </Col>
//           </Row>
//           {showAge && (
//             <Row>
//               <Col xs={12}>
//                 <Select
//                   onChange={(select) => addChildren(select)}
//                   styles={{
//                     option: (provided, state) => ({
//                       ...provided,
//                       padding: "3px 12px",
//                       fontSize: "15px",
//                     }),
//                     control: ({ width, ...css }) => ({
//                       width: "max-content",
//                       minWidth: "100%",
//                     }),
//                     menuList: ({ width, ...css }) => ({
//                       textAlign: "left",
//                       maxHeight: "200px",
//                       overflowY: "scroll",
//                     }),
//                     valueContainer: (provided, state) => ({
//                       ...provided,
//                       display: "none",
//                     }),
//                     indicatorsContainer: (provided, state) => ({
//                       ...provided,
//                       display: "none",
//                     }),
//                   }}
//                   menuPlacement="auto"
//                   maxMenuHeight={220}
//                   autosize={true}
//                   options={agesList}
//                   menuIsOpen={true}
//                 />
//               </Col>
//             </Row>
//           )}
//         </div>
//       )}
//       <input type="hidden" name="adults" value={adults.toString()} />
//       <input type="hidden" name="kids" value={kids.join(",")} />
//     </Col>
//   );
// };

// export default GuestsForm;
