//@ts-nocheck
"use client"
import React, { FC } from "react";
import Testimonials from "./templates/Testimonials";
import Services from "./templates/Services";
import Rooms from "./templates/Rooms";
import Hotels from "./templates/Hotels";
import Therapy from "./templates/Therapy";

interface IProps {
    type?: string;
    slider?: boolean;
    name?: string;
    title?: string;
    loadFromApi?: boolean;
};

export const MainList: FC<IProps> = ({
    type = "hotels",
    slider = true
}) => {
    switch (type) {
        case 'hotels': return <Hotels />;
            break;
        case 'reviews': return <Testimonials />;
            break;
        case 'services': return (<Services slider={slider} />)
            break;
        case 'rooms': return (<Rooms slider={slider} />)
            break;
        case 'therapy': return (<Therapy slider={slider} />)
            break;
        default: return null;
            break;
    }
};
