//@ts-nocheck
"use client"
import React, { FC, useEffect, useState } from "react";
import Link from 'next/link';
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { useLandingContext } from "../../../../../context/LandingProvider";
import { api } from "../../../../../services/api/Api";
import { helpers } from "../../../../../services/_helpers/Helpers";
import { ImageSrc } from "../../../../../componentsNew/elements/ImageSrc";
import classes from "./Services.module.css";

interface IProps {
    slider?: boolean
};

const Rooms: FC<IProps> = ({ slider = true }) => {
    const { landing, theme } = useLandingContext();
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [notFound, setNotFound] = useState<boolean>(false);
    useEffect(() => {
        async function fetchData() {
            const query = {
                $and: [
                    { publish: true },
                    { statusLanding: true },
                    { "hotels._id": theme?.bagtravelsId }
                ]
            };
            const result = await api.bagtravels.post("rooms", { filter: query });

            (Array.isArray(result) && result.length > 0) ? setData(result) : setNotFound(true);
            setIsLoading(false);
        }
        fetchData();
    }, []);
    if (notFound || isLoading) return null;
    if (slider) {
        return (
            <Carousel
                {...helpers.data.defaultCarouselProps()}
                autoPlaySpeed={3000}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    }
                }}
            >
                {data.map((item, index) => {
                    return (
                        <div className={`${classes.itemContainer} rounded`} key={"rooms-" + item._id}>
                            <div className={classes.imgContainer}>
                                <Link href={`/rooms/${item.title_slug}`} as={`/rooms/${item.title_slug}`}>
                                    {item.startPrice &&
                                        <div className={classes.price}>От {item.startPrice} Руб/Чел</div>
                                    }
                                    <ImageSrc asset={item.image} storage={true} cdn={true} />
                                    {/* <img src={imageSrc} className={classes.imgContainerImg} /> */}
                                </Link>
                            </div>
                            <div className={classes.itemTitle} style={{ backgroundColor: "rgba(0,0,0,.7)" }}>
                                <h5 className={`${classes.itemTitleH3} text-center px-3 py-4`}><strong><a
                                    href={`/rooms/${item.title_slug}`}>{item.title}{item.housingName && `, ${item.housingName}`}</a></strong></h5>
                            </div>
                        </div>
                    )
                })}

            </Carousel>
        );

    } else {
        return (
            <Container>
                <Row>
                    {data.map((item, index) => {
                        return (
                            <Col xs={12} md={4} className={`mx-0 px-0`} key={"rooms-" + item._id}>
                                <div className={`${classes.itemContainer} rounded`}>
                                    <div className={classes.imgContainer}>
                                        <a href={`/rooms/${item.title_slug}`}>

                                            {item.startPrice &&
                                                <div className={classes.price}>От {item.startPrice} Руб/Чел</div>
                                            }
                                            <ImageSrc asset={item.image} storage={true} cdn={true} />
                                        </a>
                                    </div>
                                    <div className={classes.itemTitle} style={{ backgroundColor: "rgba(0,0,0,.7)" }}>
                                        <h5 className={`${classes.itemTitleH3} text-center px-3 py-4`}><strong><a
                                            href={`/rooms/${item.title_slug}`}>{item.title}{item.housingName && `, ${item.housingName}`}</a></strong></h5>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        )
    }
};

export default Rooms;