"use client"
import React from "react";
import {
    ContactsInfo,
    Divider,
    Grid,
    Heading,
    BtHeading,
    Html,
    Text,
    SaleSlider,
    ListIcon,
    MainList,
    MarginTop,
    Js,
    FeedbackForm,
    GridGallery,
    ViewMap,
    cockpitGallery,
    Image,
    // CollectionLink,
    // ItemGrid,
    // Divider,
    // Stocks,
    Section,
    TabComponent,
    // Button,
    // SvgIcons
} from "./cockpit";
import Accordions from "./ui/accordion/Accordions";
import Collapse from "./ui/collapse/Collapse";
import ContainerCollection from "../../componentsNew/ui/container/ContainerCollection";
import CustomMenu from "./cockpit/CustomMenu/CustomMenu";
import ImageAndText from "./cockpit/ImageAndText/ImageAndText";
import ProductCard from "./cockpit/ProductCard/ProductCard";
import SearchForm from "../../componentsNew/form/SearchForm/SearchForm";
import Slider from "../Sliders/Slider";
import SliderCollection from "../../componentsNew/ui/sliders/sliderCollection/SliderCollection";
import SliderCollectionUpdated from "../../componentsNew/ui/sliders/sliderCollection/SliderCollectionUpdated.tsx";
import SliderText from "../../componentsNew/ui/sliders/sliderText/SliderText";
import TextSeparator from "../../componentsNew/ui/text/separator/TextSeparator";
import AnimatedComponent from "../../componentsNew/animate/AnimatedComponent";
//
const defaultComponents = {
    accordion: Accordions,
    btheading: BtHeading,
    contactsinfo: ContactsInfo,
    containercollection: ContainerCollection,
    custommenu: CustomMenu,
    // button: Button,
    // collapse: Collapse,
    // collectionlink: CollectionLink,
    divider: Divider,
    feedbackform: FeedbackForm,
    formbooking: SearchForm,
    grid: Grid,
    listicon: ListIcon,
    lists: MainList,
    gridgallery: GridGallery,
    gallery: cockpitGallery,
    heading: Heading,
    html: Html,
    image: Image,
    imageandtext: ImageAndText,
    js: Js,
    margintop: MarginTop,
    productcard: ProductCard,
    // itemgrid: ItemGrid,
    // stocks: Stocks,
    saleslider: SaleSlider,
    section: Section,
    slider: Slider,
    slidercollection: SliderCollectionUpdated,
    slidertext: SliderText,
    // svgicons: SvgIcons
    tabs: TabComponent,
    text: Text,
    textseparator: TextSeparator,
    viewmap: ViewMap,
};

// const dynComponents = (layouts) => {
//     console.log("layouts", layouts);
//     return null;
// };
const DynComponents = (layouts, save = false) => {
    // Validate layouts and components
    if (!layouts || !Array.isArray(layouts.components)) {
        console.warn("Invalid layouts object or components missing.");
        return null;
    }

    return layouts.components
        .filter((comp) => comp.component && typeof comp.component === "string") // Filter valid components
        .map((component_parsed, idx) => {
            const name = component_parsed.component.toLowerCase();
            const settings = component_parsed.settings;
            const children = component_parsed.children || [];
            const columns = component_parsed.columns || [];
            if (!defaultComponents[name]) {
                console.warn(`Component "${name}" is not registered.`);
                return null;
            }

            const Component = defaultComponents[name];
            if (settings && settings.animate) {
                return (
                    <AnimatedComponent key={`animate-${settings?.animate?.id}`} animate={settings.animate}>
                        <Component
                            name={name}
                            children={children}
                            columns={columns}
                            key={`component-${name}-${idx}`}
                            idx={idx}
                            save={save}
                            {...settings}
                        />
                    </AnimatedComponent>
                );

            } else {
                return (
                    <Component
                        name={name}
                        children={children}
                        columns={columns}
                        key={`component-${name}-${idx}`}
                        idx={idx}
                        save={save}
                        {...settings}
                    />
                );

            }
        })
        .filter(Boolean); // Remove null values from the result
};

export default DynComponents;
