"use client"
import React, { FC } from "react";
import { useSearchParams } from 'next/navigation';
import { Col, Button } from "react-bootstrap";
import classes from "../SearchForm.module.css";
import GuestsForm from "../guestsForm/GuestsForm";
import DateRangeWithNights from "../dateRangeWithNights/DateRangeWithNights";

interface IProps { }

const getInitialValues = (searchParams) => {
  const checkIn = searchParams.get('checkIn');
  const checkOut = searchParams.get('checkOut');
  const adults = searchParams.get('adults');
  const kids = searchParams.getAll('kids');
  const nights = searchParams.get('nights');

  // let newKids = kids.length > 0) ?kids : [];
  // if (kids.length > 0) {
  //   newKids = Array.isArray(kids) ? kids : [kids];
  // }

  const checkInValue = checkIn ? checkIn : null;
  const checkOutValue = checkOut ? checkOut : null;
  const nightsValue = nights ? nights : null;

  return {
    checkIn: checkInValue,
    checkOut: checkOutValue,
    nights: nightsValue,
    initial: {
      adults,
      kids,
    },
  };
};

const Vertical: FC<IProps> = () => {
  const searchParams = useSearchParams();
  const initialValues = getInitialValues(searchParams);
  return (
    <div className="row align-items-center justify-content-between">
      {/* @ts-ignore */}
      <Col
        xs="12"
        className="d-flex flex-nowrap align-items-center justify-content-center mb-3"
      >
        {/* <i className={`${classes.check} ${classes.checkMobile}`}></i> */}
        {/* @ts-ignore */}
        <span className={classes.vtext}>Бронирование номеров</span>
      </Col>
      {/* @ts-ignore */}
      <Col xs="12" className="d-flex flex-nowrap justify-content-between mb-3 align-items-end">
        {/* @ts-ignore */}
        <DateRangeWithNights {...initialValues} />
      </Col>
      {/* @ts-ignore */}
      <Col
        xs="12"
        className="d-flex flex-nowrap justify-content-between mb-3"
      >
        {/* @ts-ignore */}
        <GuestsForm viewDialog initial={initialValues.initial} />
      </Col>
      {/* @ts-ignore */}
      <Col xs="12">
        {/* @ts-ignore */}
        <Button type="submit" className={`${classes.buttonMain} w-100`}>
          Найти
        </Button>
      </Col>
    </div>
  );
};

export default Vertical;
