"use client"
import React from 'react';
import PropTypes from "prop-types";
import Icon from "../../ui/icon/Icon";
import Carousel from "react-multi-carousel";

const SvgIcons = (props) => {
    const {
        icons,
        idx,
        ...other
    } = props;
    let className = props.class ? props.class : '';

    if (icons.length > 0) {
        return (
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay={true}
                autoPlaySpeed={6000}
                centerMode={false}
                className="rhomb-carousel"
                containerClass="container-with-dots py-3"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 4,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {icons.map((icon, index) => {
                    const item = icon.value;
                    if (item && item != null) {
                        return (
                            <Icon
                                class={className}
                                col
                                key={"icon-" + index}
                                title={item.title}
                                desc={item.desc}
                                svg={item.svg}
                                width="40px"
                                height="40px"
                            >
                            </Icon>
                        )

                    } else {
                        return (<></>);
                    }
                })}
            </Carousel>
        );

    } else {
        return (<></>);
    }

};
export default SvgIcons;



SvgIcons.propTypes = {
    icons: PropTypes.array
};

SvgIcons.defaultProps = {
    icons: []
}





