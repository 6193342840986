"use client"
import React from "react";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import classes from "./ContactsInfo.module.css";
import { useLandingContext } from "../../../../context/LandingProvider";

const ContactsInfo = ({ text = '', title = '' }) => {
    const { theme } = useLandingContext();
    return (
        <div className={classes.mainBox}>
            {title && title.length != 0 ? <h3 className="my-3">{title}</h3> : theme.headerTitle ? <h3 className="my-3">{theme.headerTitle}</h3> : null}
            <ul className="px-0">
                {theme.address ? <li><i className={classes.address}></i><strong>Адрес:</strong> {theme.address}</li> : null}
                {text && <div className="my-3">{ReactHtmlParser(text)}</div>}
                {theme.worckTime ? <li><i className={classes.time}></i><strong>Время работы:</strong> {theme.worckTime}</li> : null}
                {theme.phones && theme.phones.map((phone, idx) => {
                    const { title } = phone.value;
                    return (<li key={`con-info-${idx}`}><i className={classes.phone}></i><strong>Телефон:</strong> <a href={`tel:${phone.title}`}>{title}</a></li>)
                })}
                {theme.email ? <li><i className={classes.email}></i><strong>Email:</strong> <a href={`mailto:${theme.email}`}>{theme.email}</a></li> : null}
            </ul>
        </div>
    )
}

export default ContactsInfo;
