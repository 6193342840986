import React from "react";

const Button = ({className, type, text, url, size, color}) => {
    const arrClass = ['btn'];
    if (className) {
        arrClass.push(className);
    }
    if (size) {
        arrClass.push(size);
    }
    if (color) {
        arrClass.push(color);
    } else {
        arrClass.push('btn-primary');
    }
    const Tag = url ? 'a' : 'button';
    const href = url ? `href="${url}"` : null;
    return (
        <Tag href type={type || "button"} className={arrClass.join(" ")}>
            {text}
        </Tag>
    )
};
export default Button;