"use client"
import React from 'react';
import { MDBCol, MDBView, MDBTooltip, MDBPopper } from "mdbreact";
import PropTypes from "prop-types";
import Conf from "../../../../config";


const Icon = (props) => {
    const {
        title,
        tooltip,
        desc,
        svg,
        img,
        col,
        alt,
        idx,
        tagTitle,
        width,
        height,
        ...other
    } = props;
    let className = props.class ? props.class : "icon-box style4";
    let iconWidth = width ? width : '30px';
    let iconHeight = height ? height : '30px';
    let content = () => {

        if (tooltip) {

            return (
                <MDBPopper
                    placement="bottom"
                    domElement
                    tag="span"
                    style={{ background: "#ccc" }}
                >
                    <div className={className} key={idx} title={tagTitle} alt={alt} >
                        {!svg ? '' : <svg width={iconWidth} height={iconHeight}><use xlinkHref={Conf.siteUrl + svg}></use></svg>}
                        {!title ? '' : <p className="box-title">{title}</p>}
                        {!desc ? '' : <p className="description">{desc}</p>}
                    </div>
                    <span>{alt}</span>
                </MDBPopper>
            )
        } else {
            return (
                <div className={className} key={idx} title={tagTitle} alt={alt} >
                    {!svg ? '' : <svg width={iconWidth} height={iconHeight}><use xlinkHref={Conf.siteUrl + svg}></use></svg>}
                    {!title ? '' : <p className="box-title">{title}</p>}
                    {!desc ? '' : <p className="description">{desc}</p>}
                </div>
            )
        }

    }
    if (col) {
        return (
            <MDBCol className="col-auto">
                {content()}
            </MDBCol>
        );
    } else {
        return (
            <React.Fragment>
                {content()}
            </React.Fragment>
        );
    }
};


export default Icon;



Icon.propTypes = {
    title: PropTypes.string,
    tagTitle: PropTypes.string,
    desc: PropTypes.string,
    svg: PropTypes.string,
    img: PropTypes.string,
};

Icon.defaultProps = {
    title: '',
    tagTitle: null,
    desc: '',
    svg: '',
    img: '',
    col: null
}





