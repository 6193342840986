import React, { useState } from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Col,
    Row,
    Button,
    Alert,
    FormLabel, FormControl, FormCheck, FormGroup
} from "react-bootstrap";

import classes from "./HorizontalFormBooking.module.css";


import Utils from "../../../utils/all";
import { cmsApi } from "../../api/cms";
import { useLandingContext } from "../../../context/LandingProvider";
import { api } from "../../../services/api/Api";


const TestimonialForm = () => {
    const { landing, theme } = useLandingContext();
    const [alert, setAlert] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Заполните это поле"),
        email: Yup.string()
            .email("Введен недействительный адрес электронной почты")
            .required("Заполните это поле"),
        message: Yup.string()
            .required("Заполните это поле"),
    });
    const SubmitHandler = async (event) => {
        event.preventDefault();
        // const startDate = event.target.startDate.value;
        // const endDate = event.target.endDate.value;
        const name = event.target.name.value;
        const email = event.target.email.value;
        const message = event.target.message.value;
        const title = Utils.cut_text(message, 10);

        const site = await cmsApi.getSite(domain.name);

    };
    return (
        <Row>
            <Col className={`${classes.formBox} py-2 px-3 w-100`}>
                <Formik
                    initialValues={{
                        alert: false,
                        name: "",
                        email: "",
                        message: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                        await new Promise((r) => setTimeout(r, 500));
                        const query = {
                            publish: false,
                            lastName: values.name,
                            email: values.email,
                            text: values.message,
                            site: {
                                _id: landing._id,
                                link: "sites",
                                display: landing.domain
                            }
                        }
                        await api.cms.save("comments", query).then((res) => setAlert(true));

                        setDisabled(true)
                        setIsSend(true)
                        setAlert(true)
                    }}
                >
                    {({ values, errors, handleChange, handleBlur, touched, status, isSubmitting }) => (
                        <Form>
                            {/* @ts-ignore */}
                            <FormGroup className="form-group mb-3  required">
                                <FormLabel
                                    className="text-right text-dark control-label">
                                    Ваше ФИО
                                </FormLabel>
                                <FormControl
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    size="sm" name="name" type="text"
                                    value={values.name}
                                    isValid={touched.name && !errors.name}
                                    isInvalid={!!errors.name}
                                />
                                {touched.name && errors.name ? (
                                    <div className="error-message">{errors.name}</div>
                                ) : null}
                            </FormGroup>
                            <FormGroup className="form-group mb-3 required">
                                <FormLabel
                                    className="text-right text-dark control-label">
                                    Ваш Email
                                </FormLabel>
                                <FormControl
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    size="sm" name="email" type="text" value={values.email}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={!!errors.email}
                                />
                                {touched.email && errors.email ? (
                                    <div className="error-message">{errors.email}</div>
                                ) : null}
                            </FormGroup>
                            {/* @ts-ignore */}
                            <FormGroup className="form-group mb-3 required">
                                <FormLabel
                                    className="text-right text-dark">
                                    Отзыв
                                </FormLabel>
                                <FormControl
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="message"
                                    as="textarea" rows={3}>{values.message}</FormControl>
                            </FormGroup>
                            <hr />
                            <Row>
                                {/* @ts-ignore */}
                                <Col xs={12}>
                                    {/* @ts-ignore */}
                                    <p className="text-right"><span className="required-info"></span> -
                                        поля, обязательные для заполнения</p>
                                </Col>
                            </Row>

                            <FormGroup as={Row} className="mb-3 required">
                                {/* @ts-ignore */}
                                <Col xs={12}>
                                    {/* @ts-ignore */}
                                    <FormCheck type="checkbox">
                                        <FormCheck.Input
                                            required
                                            type="checkbox"
                                        />
                                        <FormCheck.Label className="text-dark control-label">Подтверждаю
                                            своё согласие на передачу в электронном виде регистрационной
                                            формы, а так же на обработку и хранение информации (в том
                                            числе персональных данных)</FormCheck.Label>
                                    </FormCheck>
                                </Col>
                            </FormGroup>
                            <Row className="justify-content-center">
                                <Col xs={12}
                                //sm={{span: 10, offset: 2}}
                                >
                                    <Button
                                        disabled={disabled}
                                        type="submit"
                                    >Отправить отзыв</Button>
                                </Col>
                            </Row>

                            {alert &&
                                <Row className="justify-content-center">
                                    <Col xs="12 my-2">
                                        <Alert variant={"success"}>
                                            Отзыв добавлен
                                        </Alert>
                                    </Col>
                                </Row>
                            }
                        </Form>
                    )}
                </Formik>
            </Col>

        </Row>
    );
};

export default TestimonialForm;
