//@ts-nocheck
"use client"
import React, { FC, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { useLandingContext } from "../../../../../context/LandingProvider";
import { helpers } from '../../../../../services/_helpers/Helpers';
import classes from "./Therapy.module.css";
import { ImageSrc } from "../../../../../componentsNew/elements/ImageSrc";
import Utils from "../../../../../utils/all";
import { api } from "../../../../../services/api/Api";

interface IProps {
    slider?: boolean
};

const Therapy: FC<IProps> = ({ slider = false }) => {
    const { landing, theme } = useLandingContext();
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [notFound, setNotFound] = useState<boolean>(false);
    useEffect(() => {
        async function fetchData() {
            const query = {
                filter: {
                    "site._id": landing._id,
                    publish: true
                },
                fields: { title: 1, title_slug: 1, image: 1 },
                simple: 1
            };
            const result = await api.cms.get("therapy", query);
            (Array.isArray(result) && result.length > 0) ? setData(result) : setNotFound(true);
            setIsLoading(false);
        }
        fetchData();
    }, []);
    if (notFound || isLoading) return null;
    if (slider) {
        return (
            <Carousel
                {...helpers.data.defaultCarouselProps()}
                autoPlaySpeed={3000}
                className="service-carousel"
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    }
                }}
            >
                {data.map((item, index) => {
                    return (
                        <div className={`${classes.itemContainer} rounded`} key={"serv-" + item._id}>
                            <div className={`srvImg ${classes.imgContainer}`}>
                                <a href={`/therapy/${item.title_slug}`}>
                                    <ImageSrc asset={item.image} cdn={false} />
                                    {/* <img src={imageSrc} className={classes.imgContainerImg} /> */}
                                </a>
                            </div>
                            <div className={classes.itemTitle} style={{ backgroundColor: "rgba(0,0,0,.6)" }}>
                                <h5 className={`${classes.itemTitleH3} text-center px-3 py-4`}><strong><a
                                    href={`/therapy/${item.title_slug}`}>{item.title}</a></strong></h5>
                            </div>
                        </div>
                    )
                })}

            </Carousel>
        );
    } else {
        return (
            <Container>
                <Row>
                    {data.map((item, index) => {
                        return (
                            <Col xs={12} md={6} className={`mx-0 px-0`} key={"srv-" + item._id}>
                                <div className={classes.itemContainer}>
                                    <div className={`srvImg ${classes.imgContainer}`}>
                                        <a href={`/therapy/${item.title_slug}`}>
                                            <ImageSrc asset={item.image} cdn={false} />
                                            {/* <img src={imageSrc} className={classes.imgContainerImg} /> */}
                                        </a>
                                    </div>
                                    <div className={classes.itemTitle} style={{ backgroundColor: theme.mainColor ? Utils.hexToRgbA(theme.mainColor, .9) : "rgba(0,0,0,0.9)" }}>
                                        <h5 className={`${classes.itemTitleH3} text-center px-3 py-4`}><strong><a
                                            href={`/therapy/${item.title_slug}`}>{item.title}</a></strong></h5>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        )
    }
};

export default Therapy;