"use client"
import React, { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import classes from "../timeline/Timeline.module.css";
import Utils from "../../../../utils/all";
import DynComponents from "../../DynComponents";
import convert from "htmr";

const Accordions = (props) => {
    const className = props.class ? props.class : false;
    const allowMultipleExpanded = props.allowMultipleExpanded ? props.allowMultipleExpanded : false;
    const items = props.items ? props.items : false;
    let preExpanded = [];
    if (!Utils.isEmpty(items)) {
        items.map((itemValue, idx) => {
            let item = itemValue.value;
            item.expand ? preExpanded.push(`item-${idx}`) : null;
        })
    } else {
        return null;
    }
    return (
        <Accordion
            className={classes.accordion}
            allowMultipleExpanded={allowMultipleExpanded}
            preExpanded={preExpanded}
        >
            {items.map((itemValue, idx) => {
                let item = itemValue.value;
                return (
                    <AccordionItem uuid={`item-${idx}`} className={classes.panel}>
                        <AccordionItemHeading className={classes.header}>
                            <AccordionItemButton>
                                <i className={classes.iconTitle}></i><span className={classes.title}> {item.title}</span>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className={classes.itemPanel}>
                            {/*{!Utils.isEmpty(item.content)*/}
                            {/*    ?*/}
                            {/*    <React.Fragment>*/}
                            {/*        { convert(item.content)}*/}
                            {/*    </React.Fragment>*/}
                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}
                            {!Utils.isEmpty(item.layouts)
                                ?
                                <DynComponents components={item.layouts} />
                                :
                                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                            }
                        </AccordionItemPanel>
                    </AccordionItem>
                )

            })}
        </Accordion>
    )
}
export default Accordions