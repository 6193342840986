import React, { useEffect, useState } from 'react';
import { helpers } from '../../services/_helpers/Helpers';
import classes from './AnimatedComponent.module.css';

const AnimatedComponent = ({ animate, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Элемент появился на экране
                }
            },
            {
                threshold: [0.05, 0.15, 0.20, 0.30, 0.60], // Порог, когда элемент будет считаться видимым
            }
        );

        const element = document.getElementById(animate.id);
        if (element) observer.observe(element);

        return () => {
            if (element) observer.unobserve(element);
        };
    }, []);

    return (
        <div
            id={animate.id}
            className={`${isVisible ? `${classes.afterAnimation} ${helpers.data.animateClass(animate)}` : "opacity-0"
                }`}
        >
            {children}
        </div>
    );
};

export default AnimatedComponent;
