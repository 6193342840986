//@ts-nocheck
"use client"
import React, { FC, useEffect, useState } from "react";
import { Container, Row, Col, Carousel, CarouselItem, Button, Spinner } from "react-bootstrap";
import convert from "htmr";
import { helpers } from "../../../../services/_helpers/Helpers";
import { ImageSrc } from "../../../elements/ImageSrc";
import DynComponents from "../../../../src/components/DynComponents";
import { api } from "../../../../services/api/Api";
import { useAuthJWTContext } from "../../../../context/AuthJWTProvider";
import classes from "./SliderCollection.module.css";
import { ReactSVG } from 'react-svg';



interface IProps {
    link: any;
    publish?: boolean;
    activeIndex?: number;
    indicators?: boolean;
    interval?: number;
}

const SliderCollectionUpdated: FC<IProps> = ({
    link,
    publish,
    activeIndex = 0,
    indicators = true,
    interval = 9000
}) => {
    const { auth } = useAuthJWTContext();
    const [slides, setSlides] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSend, setIsSend] = useState<boolean>(false);
    const [index, setIndex] = useState<number>(0);

    useEffect(() => {
        async function fetchData() {
            const query = {
                filter: {
                    _id: link._id
                },
                load_css: true
            };
            const items = await api.cms.get("slides", query, true);
            if (items) {
                setData(items);
                setSlides(items.slider.map((item, idx) => ({
                    ...item,  // Сохраняем все свойства элемента
                    index: idx // Добавляем индекс
                })).filter(item => item.publish));
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);
    // List of CSS properties to ignore
    const IGNORED_STYLES = helpers.data.ignoreStyle();
    const addUniqueIdToCSS = (cssString, uniqueId) => {
        return cssString
            .split("\n") // Разбиваем на строки
            .map(line => {
                // Игнорируем строки, начинающиеся с `@` (например, `@media`, `@keyframes`)
                if (line.trim().startsWith("@")) {
                    return line;
                }

                // 1. Удаляем `body` в начале селектора
                line = line.replace(/(^|[\s{>])body\s*>?/g, "$1");

                // 2. Проверяем, начинается ли селектор с `#uniqueId`
                return line.replace(/^(\s*)([^{}]+)(?=\s*\{)/g, (match, space, selector) => {
                    let selectors = selector.split(",").map(s => s.trim()); // Разделяем селекторы по `,`

                    selectors = selectors.map(s => {
                        if (s.startsWith(`#${uniqueId}`)) {
                            return s; // Если `#uniqueId` уже есть, не трогаем
                        }
                        return `#${uniqueId} ${s}`; // Добавляем `#uniqueId`, если его нет
                    });

                    return space + selectors.join(", "); // Собираем селекторы обратно
                });
            })
            .join("\n"); // Склеиваем строки обратно
    };


    const extractContentBoxStyles = (carouselItemId) => {
        const carouselBox = document.querySelector(`#${carouselItemId} .sliderBox`);
        if (!carouselBox) return "";

        let cssString = `.sliderBox {\n`;
        cssString += extractElementStyles(carouselBox, false); // Стили для основного элемента
        cssString += `}\n`;
        const contentBox = document.querySelector(`#${carouselItemId} .sliderBox .contentBox`);
        if (contentBox) {
            cssString += `.contentBox {\n`;
            cssString += extractElementStyles(contentBox, true); // Стили для .contentBox
            cssString += `}\n`;

            const children = Array.from(contentBox.children);
            children.forEach((child, index) => {
                const nthChildSelector = `:nth-child(${index + 1})`;
                const uniqueSelector = `.contentBox > ${child.tagName.toLowerCase()}${nthChildSelector}`;
                cssString += `${uniqueSelector} {\n`;
                cssString += extractElementStyles(child, true); // Стили для дочерних элементов
                cssString += `}\n`;

                // Рекурсивно обрабатываем вложенные элементы
                cssString += extractChildStyles(child, uniqueSelector);
            });
        }

        return cssString;
    };
    const extractElementStyles = (element, includeComputed) => {
        if (element.classList.contains("ignore")) {
            return "";
        }

        const styles = window.getComputedStyle(element); // Все computed-стили элемента
        const inlineStyles = element.style; // Inline-стили элемента

        let cssString = "";
        const addedProperties = new Set(); // Отслеживаем уже добавленные свойства

        // Функция для извлечения стилей из <style> и файлов стилей
        const getOriginalStyles = (element) => {
            for (const sheet of document.styleSheets) {
                try {
                    const rules = sheet.cssRules || sheet.rules;
                    for (const rule of rules) {
                        if (rule.style && element.matches(rule.selectorText)) {
                            for (let i = 0; i < rule.style.length; i++) {
                                const property = rule.style[i];
                                const value = rule.style.getPropertyValue(property);

                                // Если свойство не равно opacity: 0 и display: none, добавляем его
                                if (
                                    value &&
                                    !addedProperties.has(property) &&
                                    !IGNORED_STYLES.includes(property) &&
                                    property !== 'opacity' && value !== '0' &&
                                    property !== 'display' && value !== 'none'
                                ) {
                                    cssString += `  ${property}: ${value};\n`;
                                    addedProperties.add(property);
                                }
                            }
                        }
                    }
                } catch (e) {
                    // Игнорируем ошибки безопасности (например, стили с другого домена)
                }
            }
        };

        // Извлекаем стили из <style> тегов и файлов
        getOriginalStyles(element);

        // Затем обрабатываем inline-стили
        for (let i = 0; i < inlineStyles.length; i++) {
            const property = inlineStyles[i];
            const value = inlineStyles.getPropertyValue(property);

            if (
                value &&
                !addedProperties.has(property) &&
                !IGNORED_STYLES.includes(property) &&
                property !== 'opacity' && value !== '0' &&
                property !== 'display' && value !== 'none'
            ) {
                cssString += `  ${property}: ${value};\n`;
                addedProperties.add(property);
            }
        }

        // Добавляем недостающие computed-стили
        if (includeComputed) {
            for (let i = 0; i < styles.length; i++) {
                const property = styles[i];
                const value = styles.getPropertyValue(property);

                // Игнорируем opacity: 0 и display: none
                if (
                    value &&
                    !addedProperties.has(property) &&
                    !IGNORED_STYLES.includes(property) &&
                    value !== "initial" &&
                    value !== "none" &&
                    !(property === "opacity" && value === "0") &&
                    !(property === "display" && value === "none")
                ) {
                    cssString += `  ${property}: ${value};\n`;
                    addedProperties.add(property);
                }
            }
        }

        return cssString;
    };



    // const getOriginalStyle = (element, property) => {
    //     for (const sheet of document.styleSheets) {
    //         try {
    //             const rules = sheet.cssRules || sheet.rules;
    //             for (const rule of rules) {
    //                 if (rule.style && element.matches(rule.selectorText)) {
    //                     const value = rule.style.getPropertyValue(property);
    //                     if (value) return value; // Возвращаем оригинальное значение
    //                 }
    //             }
    //         } catch (e) {
    //             // Игнорируем ошибки безопасности (другие домены)
    //         }
    //     }
    //     return null;
    // };

    // Рекурсивное извлечение стилей для дочерних элементов
    const extractChildStyles = (element, parentSelector) => {
        let cssString = "";    // Skip if the element has the "ignore" class
        if (element.classList.contains("ignore")) {
            return cssString;
        }
        const children = Array.from(element.children);

        children.forEach((child, index) => {
            const nthChildSelector = `:nth-child(${index + 1})`;
            const uniqueSelector = `${parentSelector} > ${child.tagName.toLowerCase()}${nthChildSelector}`;

            // Извлекаем стили для дочернего элемента
            cssString += `${uniqueSelector} {\n`;
            cssString += extractElementStyles(child, true); // Extract styles for the child
            cssString += `}\n`;

            // Рекурсивно обрабатываем вложенные элементы
            cssString += extractChildStyles(child, uniqueSelector);
        });

        return cssString;
    };

    const save = async (index, _id) => {
        setIsSend(true);
        const carouselItemId = `slider-${link._id}-${index}`;
        const styles = extractContentBoxStyles(carouselItemId);
        // console.log("carouselItemId", index);
        // const updatedSlides = [...slides];
        // updatedSlides[index] = {
        //     ...updatedSlides[index],
        //     style: styles, // Add new key `style`
        // };
        const query = {
            _id: _id,
            css: styles,
        };
        await api.cms.save('css', query);
        setIsSend(false);
        // Send data to server or use it otherwise
    };

    if (!Array.isArray(slides) || isLoading) return null;

    return (
        <Row className="justify-content-center d-none d-lg-block">
            <Col xs={12}>
                <Carousel
                    onSlide={(index) => { setIndex(index); }}
                    fade={true}
                    indicators={indicators}
                    prevLabel=""
                    nextLabel=""
                    interval={interval}
                    touch={true}
                >
                    {slides.map((slide, idx) => {
                        const image = helpers.image.getUrlImage(slide?.image, false, false);
                        return (
                            <CarouselItem
                                key={`slider-${link._id}-${slide.index}`}
                                id={`slider-${link._id}-${slide.index}`}
                                className="position-relative"
                            // style={{
                            //     // backgroundSize: slide.bgSize === "custom" ? slide.bgScale : slide.bgSize,
                            //     // display: "block",
                            //     width: "100%",
                            //     height: "550px",
                            //     backgroundImage: `url(${image.webP})`,
                            //     backgroundRepeat: "no-repeat",
                            //     // justifyContent: "center",
                            //     // alignItems: "center"
                            //     // backgroundPosition: slide.bgPosition
                            // }}
                            >
                                {slide.type && slide.type == 'sale' && <div className={classes.art} />}
                                {slide.type && slide.type == 'sale' && <ReactSVG className={classes.icon} src={`./img/sale/sale-${slide.saleType ? slide.saleType : "11"}.svg`} />}
                                {slide?.css?.save ? (
                                    <style className="slider-style">
                                        {addUniqueIdToCSS(slide.css.save, `slider-${link._id}-${slide.index}`)}
                                    </style>
                                )
                                    : (
                                        <style className="slider-style">
                                            {addUniqueIdToCSS(slide.css.css, `slider-${link._id}-${slide.index}`)}
                                        </style>
                                    )
                                }

                                <div
                                    style={{
                                        // backgroundSize: slide.bgSize === "custom" ? slide.bgScale : slide.bgSize,
                                        // display: "block",
                                        width: "100%",
                                        height: "550px",
                                        backgroundImage: `url(${image.webP})`,
                                        backgroundRepeat: "no-repeat",
                                        // justifyContent: "center",
                                        // alignItems: "center"
                                        // backgroundPosition: slide.bgPosition
                                    }}
                                    id="sliderBox"
                                    className="sliderBox d-flex justify-content-center align-items-center"
                                >
                                    {Array.isArray(slide.layout) && slide.layout.length > 0 && (
                                        <div
                                            className={`contentBox ${slide.animate && slide.animate.status ? helpers.data.animateClass(slide.animate) : ''}`}
                                        // style={{
                                        //     position: "absolute",
                                        //     ...(slide.width && { width: slide.width }),
                                        //     ...(slide.height && { height: slide.height }),
                                        //     ...(slide.padding && { padding: slide.padding }),
                                        //     ...(slide.left && { left: slide.left }),
                                        //     ...(slide.top && { top: slide.top }),
                                        //     ...(slide.fullBackground && { backgroundColor: slide.fullBackground })
                                        // }}
                                        >
                                            <DynComponents save={true} components={slide.layout} />
                                        </div>
                                    )}
                                </div>
                                {auth && <button className="btn btn-success"
                                    style={{
                                        position: "absolute", top: "-2%", left: "40%", zIndex: 9999
                                    }}
                                    onClick={() => save(index, slide?.css?._id)}>Сохранить
                                    {isSend && <Spinner animation="border" variant="light" />}
                                </button>
                                }
                            </CarouselItem>
                        );
                    })}
                </Carousel>
            </Col>
        </Row>
    );
};

export default SliderCollectionUpdated;
