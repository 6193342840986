"use client"
import React, { FC, useEffect, useState } from "react";
import DynComponents from "../../../src/components/DynComponents";
import { api } from "../../../services/api/Api";

interface IProps {
    collection: {
        _id: string;
        display: string;
        link: string;
    }
};

const ContainerCollection: FC<IProps> = ({ collection }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>(null);
    useEffect(() => {
        async function fetchData() {
            const result = await api.cms.get("container", {
                filter: {
                    publish: true,
                    _id: collection._id
                },
                fields: {
                    title: 1,
                    layouts: 1
                }
            }, true);
            if (result) {
                setData(result);
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);
    if (isLoading) return null;
    return <DynComponents components={data.layouts} />
};

export default ContainerCollection;