"use client"
import React from "react";
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { useLandingContext } from "../../../../context/LandingProvider";

const ViewMap = ({ address = "", coordinates = [53.9, 27.56], zoom = 10 }) => {
    const { theme } = useLandingContext();
    const mapView = "d-none";
    const width = 300;
    const splitCoordinates = coordinates.split(',')
    const SpinnerPage = () => {
        return (
            <>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Загрузка...</span>
                </div>
            </>
        );
    }
    const mapState = {
        center: splitCoordinates,
        zoom: zoom
    };

    return (
        <YMaps>
            <Map
                defaultState={mapState}
                width="100%"
                height="300px">

                <Placemark
                    geometry={splitCoordinates}
                    properties={{
                        iconCaption: theme.headerTitle
                    }}
                />
            </Map>
        </YMaps>

    );
};
export default ViewMap;
