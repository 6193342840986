import axios from 'axios';
import Conf from "../../config";

export const postApi = async (url, data = {}, config) => {
    const response = await axios.post(
        url,
        data,
        config
    );
    return response;
};
export const getApi = async (url, data = {}, config) => {
    const response = await axios.get(
        url,
        data,
        config
    );
    return response;
};
export async function postSetting() {
    return axios
        .post(
            `${Conf.siteUrl}/api/themesettings`
        )
        .then(response => response.data.val)
        .catch(error => {
            console.error(error);
            return false;
        });
}
export async function postDevice() {
    return axios
        .post(
            `${Conf.siteUrl}/check/device`
        )
        .then(response => {
            const device = {
                phone: response.data.type == "phone" ? true : false,
                tablet: response.data.type == "tablet" ? true : false,
                desktop: response.data.type == "desktop" ? true : false,
                tv: response.data.type == "tv" ? true : false,
                bot: response.data.type == "bot" ? true : false,
                car: response.data.type == "car" ? true : false,
            }
            return device;
        })
        .catch(error => {
            console.error(error);
            return false;
        });
}