"use client"
import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../../utils/all";
import DynComponents from "../../DynComponents";
import { MDBCollapse } from 'mdbreact';
import convert from "htmr";
import classes from "./Collapse.module.css";

class Collapse extends Component {
    constructor(props) {
        super(props)
        this.randCollapseID = Utils.randStr(6);
        this.state = {
            collapseID: props.collapseStatus ? this.randCollapseID : "",
            collapseStatus: props.collapseStatus ? true : false,
            className: props.class ? props.class : null,
            item: {
                title: props.title ? props.title : null,
                content: props.content ? props.content : null,
                layout: props.layout ? props.layout : null,
            }
        }
    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : "",
            collapseStatus: !prevState.collapseStatus
        }));
    }
    render() {
        if (!Utils.isEmpty(this.state.item) && Object.keys(this.state.item).length > 0) {
            return (
                <div className={classes.panel}>
                    <div
                        className={classes.heading}
                        onClick={this.toggleCollapse(this.randCollapseID)}
                    >
                        <h4 className={classes.title}>
                            {this.state.item.title}
                            {this.state.collapseStatus
                                ? <FontAwesomeIcon className="float-right" size="1x" icon={faMinus} />
                                : <FontAwesomeIcon className="float-right" size="1x" icon={faPlus} />
                            }

                        </h4>
                    </div>
                    <MDBCollapse id={this.randCollapseID} isOpen={this.state.collapseID}>
                        {!Utils.isEmpty(this.state.item.layout)
                            ?
                            <DynComponents components={this.state.item.layout} />
                            :
                            <React.Fragment>{convert(this.state.item.content)}</React.Fragment>
                        }
                    </MDBCollapse>
                </div>



            );
        } else {
            return null;
        }
    }
}
export default Collapse