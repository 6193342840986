import React from "react";

const Divider = ({ name, className, color = 'orange', border = '2', width = '100' }) => (
    <React.Fragment>
        <hr
            className={className}
            style={{
                borderTop: `solid ${color} ${border}px`,
                margin: '0 auto',
                width: `${width}%`,
            }}
        />
    </React.Fragment>
);
export default Divider;