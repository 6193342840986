import React, { FC } from "react";
import classes from "./TextSeparator.module.css";

interface IProps {
    color: string
    tag?: keyof JSX.IntrinsicElements
    title: string
};

const TextSeparator: FC<IProps> = ({ color = 'blue', title = '', tag = 'h6' }) => {
    const Tag = tag as keyof JSX.IntrinsicElements;
    return (
        <div className={classes.container}>
            <span className={classes.sepHolder}>
                <span className={classes.sepHolderLine} style={{ borderColor: color }} ></span>
            </span>
            <Tag className={classes.text}>{title}</Tag>
            <span className={classes.sepHolder}>
                <span className={classes.sepHolderLine} style={{ borderColor: color }} ></span>
            </span>
        </div>
    )
};

export default TextSeparator;