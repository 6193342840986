//@ts-nocheck
import React from "react";
import { RootStateOrAny, useSelector } from 'react-redux';
import classes from "./Slider.module.css";
import PreLoading from "../components/mains/PreLoading";
import Single from "./Single";
import Utils from "../../utils/all";
import DynComponents from "../components/DynComponents";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";
import { ImageSrc } from "../../componentsNew/elements/ImageSrc";
import { useLandingContext } from "../../context/LandingProvider";

const Slider = ({ slides, backgroundText }) => {
    const { landing } = useLandingContext();
    if (!slides || !Array.isArray(slides)) return null;
    const bgText = backgroundText ? Utils.hexToRgbA(backgroundText, .7) : "rgba(243,156,18,.77)";

    return (
        <Carousel
            fade={true}
            indicators={true}
            prevLabel=''
            nextLabel=''
            interval={9000}
            touch={true}
            className={landing.screenMobile ? classes.mobileWrapper : classes.wrapper}
        >
            {slides.map((slide, idx) => {
                const { value } = slide;
                if (!value) return null;
                const { content, layouts, text } = value;
                return (
                    <Carousel.Item key={`slide-${idx}`} className="position-relative overflow-hidden">
                        <ImageSrc imageClassName={classes.image} container={false} asset={value?.image} storage={true} cdn={false} />
                        {(content || layouts || text) &&
                            <div
                                className={`${classes.contentBox} p-3`}
                                style={value.styles ? value.styles : {
                                    position: "absolute",
                                    top: "30%",
                                    left: "30%",
                                    // transform: "translate(-50 %, -50 %)",
                                    // backgroundColor: bgText,
                                }}
                            // dangerouslySetInnerHTML={{ __html: value.content }}
                            >
                                {layouts && Array.isArray(layouts) && <DynComponents components={layouts} />}
                                {text && text.map((text, idx) => {
                                    return (
                                        <div
                                            key={`sl-text-${idx}`}
                                            className={`${classes.textBox} mb-5`}
                                        //className="d-flex h-100 align-items-center justify-content-center"
                                        >
                                            <span
                                                style={{
                                                    backgroundColor: bgText
                                                }}
                                            >{text.value}</span>
                                        </div>
                                    )
                                })}
                                {content &&
                                    <div className={`${classes.content} mb-5`} >
                                        <span>{content}</span>
                                    </div>
                                }
                            </div>
                        }
                    </Carousel.Item>
                )
            })}
        </Carousel>
    )
}

export default Slider;




