import React from "react";
import { ReactSVG } from 'react-svg';
import classes from "./ProductCard.module.css";
import DynComponents from "../../DynComponents";
import { ImageSrc } from "../../../../componentsNew/elements/ImageSrc";
import { Button } from "react-bootstrap";

function ProductCard(props) {
    const { button, title, content, image } = props;

    return (
        <div className="card">
            {image && <ImageSrc asset={image} container={false} imageClassName="card-img-top" storage={false} cdn={false} />}
            <div className="card-body">
                {title ? <h4 className="text-center">{title}</h4> : null}
                {content && <DynComponents components={content} />}
                {button && <Button variant="outline-warning" href={button.link}>{button.text}</Button>}
            </div>
        </div>
    );
}

export default ProductCard;