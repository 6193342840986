//@ts-nocheck
"use client"
import React, { FC } from "react";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";
import convert from "htmr";
import { helpers } from "../../../../services/_helpers/Helpers";
import { ImageSrc } from "../../../elements/ImageSrc";
import DynComponents from "../../../../src/components/DynComponents";

interface IProps {
    slides: any
};

const SliderText: FC<IProps> = ({ slides }) => {
    if (!Array.isArray(slides)) return null;
    return (
        <Row className="justify-content-center">
            <Col xs={12}>
                <Carousel
                    fade={true}
                    indicators={false}
                    prevLabel=''
                    nextLabel=''
                    interval={9000}
                    touch={true}
                >
                    {slides.map((slide, idx) => {
                        const image = helpers.image.getUrlImage(slide?.image, false, false);
                        return (
                            <Carousel.Item className="position-relative"
                                key={`slide-${idx}`}
                                style={{
                                    backgroundSize: slide.bgSize == 'custom' ? slide.bgScale : slide.bgSize,
                                    display: 'block',
                                    width: '100%',
                                    height: '550px',
                                    backgroundImage: `url(${image.webP})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: slide.bgPosition
                                }}
                            >
                                {/* <ImageSrc imageClassName="w-100" container={false} asset={slide?.image} cdn={false} storage={false} /> */}
                                {Array.isArray(slide.layout) && slide.layout.length > 0 &&
                                    <div
                                        className="p-3"
                                        style={{
                                            position: 'absolute',
                                            ...(slide.width && { width: slide.width }),
                                            ...(slide.height && { height: slide.height }),
                                            ...(slide.padding && { padding: slide.padding }),
                                            ...(slide.left && { left: slide.left }),
                                            ...(slide.top && { top: slide.top }),
                                            ...(slide.fullBackground && { backgroundColor: slide.fullBackground }),
                                        }}
                                    >
                                        <section className={`slide-${idx}`}><DynComponents save={true} components={slide.layout} /></section>
                                    </div>
                                }

                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </Col>
        </Row>
    )
};

export default SliderText;

