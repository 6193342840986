const ArrayUtil = {
    chunkArray : function(arr,chunk){
        var i, j, tmp = [];
        for (i = 0, j = arr.length; i < j; i += chunk) {
            tmp.push(arr.slice(i, i + chunk));
        }
        return tmp;
    },
    splitTo:function( arr, n) {
        var plen = Math.ceil(arr.length / n);
        return arr.reduce(function (p, c, i) {
            if (i % plen === 0) p.push([]);
            p[p.length - 1].push(c);
            return p;
        }, []);
    }
};

export default ArrayUtil;
