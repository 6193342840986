"use client"
import React, { FC, useState, useEffect } from "react";

import "react-dates/initialize";
import { DateRangePicker, DayPickerRangeController } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import moment from "moment";
import "moment/locale/ru";
import classes from "../SearchForm.module.css";
import { helpers } from "../../../../services/_helpers/Helpers";
import { useLandingContext } from "../../../../context/LandingProvider";
import { format } from "path";
import useResize from "../../../../hooks/useResize";

interface IProps {
  initialSettings?: any;
  label?: {
    className?: string;
    status?: boolean;
    text?: string;
  };
  checkIn?: string;
  checkOut?: string;
  nights?: string;
}

const DateRangeWithNights: FC<IProps> = (props) => {
  const { theme } = useLandingContext();
  const { isMobile } = useResize();

  const [isLoading, setIsLoading] = useState<boolean>(true);


  const minSpan = theme.minSpan ? theme.minSpan : null;
  const maxSpan = theme.maxSpan && theme.maxSpan != 0 ? theme.maxSpan : 62;

  const [focusedInput, setFocusedInput] = useState(null);
  // const [startDate, setStartDate] = useState<any>(
  //   props.checkIn ? moment(props.checkIn, helpers.constant.formatDate) : moment().add(1, "days")
  // );
  // const [endDate, setEndDate] = useState<any>(
  //   props.checkOut ? moment(props.checkOut, helpers.constant.formatDate) : moment().add(minSpan ? minSpan + 1 : 2, "days")
  // );
  const [startDate, setStartDate] = useState<any>(
    props.checkIn ? moment(props.checkIn, helpers.constant.formatDate) : null
  );
  const [endDate, setEndDate] = useState<any>(
    props.checkOut ? moment(props.checkOut, helpers.constant.formatDate) : null
  );
  const [night, setNight] = useState<any>(
    moment.isMoment(startDate) && moment.isMoment(endDate) ? endDate.diff(startDate, 'days') : 0
  );

  // const [range, setRange] = useState<any>({
  //   checkIn: {
  //     name: "checkIn",
  //     value: moment().format(helpers.constant.formatDate),
  //     ...(Object.keys(props.checkIn || {}).length ? props.checkIn : {}),
  //   },
  //   checkOut: {
  //     name: "checkOut",
  //     value: moment().add(2, "days").format(helpers.constant.formatDate),
  //     ...(Object.keys(props.checkOut || {}).length ? props.checkOut : {}),
  //   },
  // });
  // const [nights, setNights] = useState<any>({
  //   status: true,
  //   value: moment(range.checkOut.value, helpers.constant.formatDate).diff(
  //     moment(range.checkIn.value, helpers.constant.formatDate),
  //     "days"
  //   ),
  //   ...props.nights,
  // });


  const label = {
    сlassName: "",
    status: true,
    text: "Даты заезда и выезда",
    ...props.label,
  };


  useEffect(() => {
    setIsLoading(false)
  }, [])

  function handleTouchStart(event) {
    event.preventDefault();
    return false;
  }
  if (isLoading) return null;
  return (
    <>
      <div className="p-0 col">
        {label.status && (
          <label className={label.className}>{label.text}</label>
        )}
        <DateRangePicker
          minimumNights={minSpan}
          minDate={moment()}
          hideKeyboardShortcutsPanel={true}
          renderDayContents={(date, action) => {
            if (startDate && !endDate && action.has('hovered')) {
              setNight(date.diff(startDate, 'days'))
            }
            return (
              <div>
                {date.format('DD')}
              </div>
            );
          }}
          noBorder
          readOnly
          startDate={startDate}
          startDateId="checkIn"
          endDate={endDate}
          orientation={isMobile ? "vertical" : "horizontal"}
          endDateId="checkOut"
          onDatesChange={({ startDate, endDate }) => {

            let getNight = 0;
            if (startDate && endDate) {
              getNight = endDate.diff(startDate, 'days');
            }
            setNight(getNight);
            setStartDate(startDate ? moment(startDate) : null);
            setEndDate(endDate ? moment(endDate) : null);
          }}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => {
            setFocusedInput(focusedInput)
          }}
          showDefaultInputIcon
          inputIconPosition="after"
          small={true}
          displayFormat={helpers.constant.formatDate}
          startDatePlaceholderText="Заезд"
          endDatePlaceholderText="выезд" x
          calendarInfoPosition="bottom"
          renderCalendarInfo={(day) => <div className={`${isMobile ? 'my-2' : 'my-3'} h-100`}><p className="py-3 fw-bold text-center">Выбрано ночей: {night}</p></div>}
          isOutsideRange={(date) => {
            if (!startDate || focusedInput == 'startDate') {
              return date.isBefore(moment().startOf('day'))
            }
            // Проверяем, превышает ли endDate максимальную дату
            const maxDate = startDate.clone().add(maxSpan, 'days');
            return date.isBefore(moment().startOf('day')) || date.isAfter(maxDate);
          }}
        />
        {/* <DateRangePicker
          initialSettings={{
            ...props.initialSettings,
            autoApply: true,
            startDate: range.checkIn.value,
            endDate: range.checkOut.value,
            minDate: moment().format(helpers.constant.formatDate),
            //   maxDate: false,
            minSpan: minSpan,
            maxSpan: maxSpan,
            format: helpers.constant.formatDate,
            locale: helpers.data.localeDatePicker,
          }}
          onCallback={(from, to) => {
            setRange({
              checkIn: {
                ...range.checkIn,
                value: from.format(helpers.constant.formatDate),
              },
              checkOut: {
                ...range.checkOut,
                value: to.format(helpers.constant.formatDate),
              },
            });
            setNights({ ...nights, value: to.diff(moment(from), "days") });
            //   setNight(to.diff(from, "days"));
          }}
        //onCallback={handleCallback}
        >
          <input type="text" name="fullDate" onTouchStart={handleTouchStart} readOnly={true} className="form-control" />
        </DateRangePicker> */}
        {/* <input type="hidden" name='checkIn' value={moment(startDate).format(helpers.constant.formatDate)} />
        <input type="hidden" name='checkOut' value={moment(endDate).format(helpers.constant.formatDate)} /> */}

        <input type="hidden" name="nights" value={night} />
      </div>
      <div className="text-center px-0 col-auto">
        <label className={`${classes.label}`}>Ночи</label>
        <span className={classes.nightIcon}>
          <span className={classes.nightText}>{night}</span>
        </span>
      </div>
    </>
  );
};

export default DateRangeWithNights;
