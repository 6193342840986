"use client"
import React from "react";
import { Container, Row } from "react-bootstrap";
import Column from "./Column";


const Grid = (props) => {
    const {
        name,
        children,
        columns,
        fluid,
        padding,
        className = '',
        settings,
        ...other
    } = props;
    let fluidContainer = fluid ? true : false;
    let paddingHorizontal = padding ? " px-0" : '';
    let reClass = className + props.class + paddingHorizontal;
    return (
        <Container
            fluid={fluidContainer}
            className={reClass}
        >
            <Row>
                {columns &&
                    columns.map((column, idx) => (
                        <Column {...column} cols={columns.length} idx={idx} key={"grid-" + Math.random(1, 322) / Math.random(1, 322) * Math.floor(4) * 10} />
                    ))}
            </Row>
        </Container>
    )
}
export default Grid