import React from "react";
import { Col } from "react-bootstrap";
import DynComponents from "../../DynComponents";
import AnimatedComponent from "../../../../componentsNew/animate/AnimatedComponent";

const Column = ({
  children,
  className,
  cols,
  settings
}) => {
  const { animate } = settings || {};
  if (!className || className.trim() === "") {
    let sm = "12";
    let md = "12";
    if (cols === 2) {
      sm = "6";
      md = "6";
    } else if (cols === 3) {
      sm = "6";
      md = "4";
    } else if (cols === 4) {
      sm = "6";
      md = "3";
    }
    className = `col-12 col-sm-${sm} col-md-${md}`;
  }
  if (animate && animate.status) {
    return (
      <Col
        className={className}
      >
        <AnimatedComponent animate={animate}><DynComponents {...cols} components={children} /></AnimatedComponent>
      </Col>
    );
  } else {
    return (
      <Col
        className={className}
      >
        <DynComponents {...cols} components={children} />
      </Col>
    );
  }
};

export default Column;