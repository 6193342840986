"use client"
import React, { useState, useEffect } from "react";
// import Gallery from "react-grid-gallery";
import { ImageGallery } from "react-image-grid-gallery";
import PreLoading from "../../mains/PreLoading";
import { api } from "../../../../services/api/Api";
import { useLandingContext } from "../../../../context/LandingProvider";

const GridGallery = ({ enable = false }) => {
    const { config, landing } = useLandingContext();
    const [images, setImages] = useState(null);
    const [thumbnailWidth, setThumbnailWidth] = useState(300);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const containerGrid = document.getElementsByClassName("grid-gallery")[0];
        if (containerGrid) {
            const containerWidth = containerGrid.offsetWidth;
            const thumbWidth = containerWidth / 4 - 30;
            setThumbnailWidth(thumbWidth);
        }

        if (enable) {
            fetchGallery();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enable]);

    const fetchGallery = async () => {
        const query = {
            filter: {
                "site._id": landing._id,
                publish: true
            }
        };
        const gallery = await api.cms.get('galery', query, true);
        if (gallery && gallery.gallery) {
            setImages(gallery.gallery.map((image) => {
                const imgWithoutExtension = image.path.split(".").slice(0, -1).join(".");
                const imageWebPSrc = imgWithoutExtension + ".webp";
                return ({
                    ...image,
                    path: imageWebPSrc
                })
            }));
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div className="grid-gallery w-100">
                <PreLoading />
            </div>
        );
    }

    if (images) {
        const galleryItems = images.map((image) => {
            return {
                src: config.imageHostNoStorage + image.path,
                // thumbnail: config.imageHostNoStorage + image.path,
                // thumbnailWidth: thumbnailWidth,
                // thumbnailHeight: thumbnailWidth,
                // caption: image.path,
                caption: image.meta && image.meta.title ? image.meta.title : '',
            };
        });
        console.log("galleryItems", galleryItems);
        return (
            <div className="grid-gallery">
                <ImageGallery
                    columnCount={4}
                    // gapSize={1}
                    // columnWidth={thumbnailWidth}
                    imagesInfoArray={galleryItems}
                />
            </div>
        );
    }

    return null;
};

export default GridGallery;
