import React from "react";
import { ReactSVG } from "react-svg";

import classes from "./Slider.module.css";
import classesSingle from "./SaleSlider.module.css";
import { useLandingContext } from "../../context/LandingProvider";

const SaleSlider = ({ text, bgText, sale, image }) => {
    const { config } = useLandingContext();
    const style = {
        backgroundImage: `url(${config.imageHostNoStorage + image.path})`,
    };
    const styleBgText = bgText ? { backgroundColor: bgText } : { backgroundColor: "transparent" };
    const currentAnimate = `animate__animated animate__slideInRight animate__delay-2s`;

    return (
        <div className={classes.wrapper}>
            <div className={classes.fullWidth}>
                <ul className="px-0 my-0">
                    <li
                        className={`${classesSingle.single} ${currentAnimate} d-flex align-items-center justify-content-center`}
                        style={style}
                    >
                        <div className={classesSingle.artBoard}></div>
                        <div style={styleBgText} className={classesSingle.textSale}>
                            {text || ""}
                        </div>
                        {sale && (
                            <ReactSVG
                                className={classesSingle.icon}
                                src={`./img/sale/sale-${sale}.svg`}
                            />
                        )}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default SaleSlider;
