"use client"
import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PropTypes from "prop-types";
import convert from "htmr";
import classes from './Tabs.module.css';
import DynComponents from '../../DynComponents';

function CustomTab({ children, ...props }) {
    return (
        <Tab
            {...props} className={classes.tab}
            selectedClassName={classes.tabSelected}
        >
            <h6>{children}</h6>
        </Tab>
    );
}

CustomTab.tabsRole = 'Tab'; // Указываем роль для совместимости с react-tabs

export default function TabComponent(props) {
    const [tabIndex, setTabIndex] = useState(0);
    const { tabs, type } = props;

    return (
        <Tabs
            className={`${classes.tabs}`}
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
        >
            <TabList className={classes.tabList}>
                {tabs.map((tab, index) => (
                    <CustomTab key={`tab-${index}`}>
                        {tab.value.title}
                    </CustomTab>
                ))}
            </TabList>
            {tabs.map((tab, index) => (
                <TabPanel
                    key={`panel-${index}`}
                    className={`${classes.tabPanel} ${tabIndex === index ? classes.active : ''}`}
                >
                    {tab.value && tab.value.content ? convert(tab.value.content) : ''}
                    {tab.value && tab.value.layouts ? <DynComponents components={tab.value.layouts} /> : ''}
                </TabPanel>
            ))}
        </Tabs>
    );
}
