import React from "react";
//
import Conf from "../../../../config";
import { ImageSrc } from "../../../../componentsNew/elements/ImageSrc";



const Image = ({ image, alt, width, height, cover }) => {
    if (!image || !image.path) return null;
    let styleContainer = null;
    let styleImg = "img-fluid";
    if (cover) {
        switch (cover) {
            case "rounded":
                styleContainer = 'rounded-3 shadow p-1 overflof-hidden'
                styleImg = "img-fluid rounded-3";
                break;
        }
    }
    let src = `${Conf.imageHostNoStorage}${image.path}`;
    return (
        <React.Fragment>
            <ImageSrc containerClassName={styleContainer} asset={image} storage={false} alt={alt} imageClassName={styleImg} />
        </React.Fragment>
    );
};
export default Image;