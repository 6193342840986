export { default as Section } from "./Section";
export { default as Grid } from "./Grid";
export { default as Heading } from "./Heading";
export { default as BtHeading } from "./BtHeading";
export { default as Html } from "./Html";
export { default as Text } from "./Text";
export { default as ListIcon } from "./ListIcon";
export { MainList } from "./Lists/MainList";
export { default as Js } from "./Js";
export { default as ContactsInfo } from "./ContactsInfo";
export { FeedbackForm } from "./FeedbackForm/FeedbackForm";
export { default as GridGallery } from "./GridGallery";
export { default as ViewMap } from "./ViewMap";
export { default as cockpitGallery } from "./Gallery";
export { default as MarginTop } from "./Helpers/MarginTop";

export { default as CollectionLink } from "./CollectionLink";
export { default as Divider } from "./Divider";
export { default as Image } from "./Image";
export { default as ItemGrid } from "./ItemGrid/ItemGrid";
export { default as Button } from "./Button";
export { default as TabComponent } from "./Tabs";
export { default as SvgIcons } from "./SvgIcons";


export { default as Slider } from "../../Sliders/Slider";
export { default as SaleSlider } from "../../Sliders/SaleSlider";
