"use client"
import React from "react";
import DynComponents from "../../DynComponents";
import { helpers } from "../../../../services/_helpers/Helpers";

// Create Main Container
const Section = ({
    animate,
    background,
    children,
    id,
    className }) => {

    let classHead = {};
    if (className || animate) {
        classHead = {
            className: `${className && className}`,
            // className: `${className && className}${animate && ` ${helpers.data.animateClass(animate)}`}`,
        };
    }

    const sectionProps = { ...classHead };
    if (id) {
        sectionProps.id = id;
    }

    if (background && background.path) {
        sectionProps.style = {
            backgroundImage: `url(${helpers.constant.cms.host}${background.path})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
        }
    }
    return (
        <section {...sectionProps}>
            <DynComponents components={children} />
        </section>
    );
};

export default Section;