"use client"
import React from "react";
import Utils from "../../../../utils/all";


class Js extends React.Component {
    constructor(props) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);

        this.elemId = false;
        this.state = {
            src: props.src ? props.src : false,
            isLoading: true,
            isServer: true,
            elemId: false
        };
    }

    addScript = () => {

        if (this.state.src) {
            const script = document.createElement('script');
            script.src = this.state.src;
            let scriptBox = document.getElementById(this.state.elemId);

            scriptBox.appendChild(script);
            script.onload = () => {
                //setTimeout(this.handleLoad, 500);
            };
        }
    }
    handleLoad = () => {

        this.setState((state) => {
            return {
                isLoading: false,
            }
        })
    }

    async componentDidMount() {
        await this.setState({
            isServer: false,
            elemId: `widget-${Utils.randStr(9)}`
        });
        await this.addScript();
    }

    render() {
        const { isLoading, elemId, isServer } = this.state;
        if (!isServer && elemId) {
            return (
                <div id={elemId}>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default Js;