//@ts-nocheck
"use client"
import React, { FC } from "react";
import { useSearchParams } from 'next/navigation';
import { Container, Col, Row, Button } from "react-bootstrap";
import classes from "../SearchForm.module.css";
import GuestsForm from "../guestsForm/GuestsForm";
import DateRangeWithNights from "../dateRangeWithNights/DateRangeWithNights";

interface IProps { }

const getInitialValues = (searchParams) => {
  const checkIn = searchParams.get('checkIn') || null;
  const checkOut = searchParams.get('checkOut') || null;
  const adults = searchParams.get('adults');
  const kids = searchParams.getAll('kids');
  const nights = searchParams.get('nights');
  // let newKids = kids.length > 0) ?kids : [];
  // if (kids.length > 0) {
  //   newKids = Array.isArray(kids) ? kids : [kids];
  // }

  const checkInValue = checkIn ? checkIn : null;
  const checkOutValue = checkOut ? checkOut : null;
  const nightsValue = nights ? nights : null;

  return {
    checkIn: checkInValue,
    checkOut: checkOutValue,
    nights: nightsValue,
    initial: {
      adults,
      kids,
    },
  };
};

const Horizontal: FC<IProps> = () => {
  const searchParams = useSearchParams();  // Using useSearchParams instead of router.query
  const initialValues = getInitialValues(searchParams);

  return (
    <Container>

      <Row className="align-items-center justify-content-between">

        <Col xs="12" md="3" className="d-flex flex-nowrap pl-0">
          <Col xs="3" className="pl-0">

            <i className={classes.check}></i>
          </Col>
          <Col xs="9" className="pr-0">
            <span className={classes.text}>Бронирование номеров</span>
          </Col>
        </Col>

        <Col
          xs="12"
          md="6"
          className="d-flex flex-nowrap p-0 justify-content-between"
        >
          <DateRangeWithNights {...initialValues} />
          <GuestsForm initial={initialValues.initial} />
        </Col>


        <Col xs="auto" className="pr-0">
          <label className={`${classes.label} w-100`}>&nbsp;</label>
          <Button type="submit" className={`${classes.buttonMain} w-100`}>
            Найти
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Horizontal;

