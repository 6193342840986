import React, { FC } from "react";
import { helpers } from "../../../../services/_helpers/Helpers";
import DynComponents from "../../DynComponents";

interface IProps {
    image: any
    alt: string
    width: string
    height: string
    content: string
};

const ImageAndText: FC<IProps> = ({ image, alt, width, height, content }) => {
    let src = `${helpers.constant.cms.host + image.path}`;
    const [flipped, setFlipped] = React.useState(false);

    const handleHover = () => {
        setFlipped(true);
    };

    const handleHoverEnd = () => {
        setFlipped(false);
    };
    return (
        <section
            onMouseEnter={handleHover}
            onMouseLeave={handleHoverEnd}
            className="cardFlip"
        >
            <div className="front">
                <img
                    src={src}
                    className="img-fluid"
                    alt={alt}
                    width={width || "100%"}
                    height={height || "100%"}
                />
            </div>
            <div className="back"
                style={{
                    fontSize: 24,
                    fontWeight: 600,
                    color: "#333",
                    margin: 20
                }}
            >
                {content && <DynComponents components={content} />}
            </div>
        </section>
    );
    return (
        <React.Fragment>
            <div
                className="card"
                onMouseEnter={handleHover}
                onMouseLeave={handleHoverEnd}
            >


            </div>
            <style>
                {`
          
        `}
            </style>
        </React.Fragment>
    );
};

export default ImageAndText;