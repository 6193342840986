"use client"
import React from "react";
import Gallery from "../../ui/gallery/Gallery";

const cockpitGallery = ({ gallery }) => {
    if (!Array.isArray(gallery) || gallery.length == 0) return null
    return <Gallery typeImage="700x450"
        thumbnailPosition="right"
        gallery={gallery} />
}

export default cockpitGallery;