//@ts-nocheck
"use client"
import React, { FC, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { Container, Row, Col, Button, Accordion, AccordionButton } from "react-bootstrap";
import { useLandingContext } from "../../../../../context/LandingProvider";
import { api } from "../../../../../services/api/Api";
import classes from "./Testimonials.module.css";
import TestimonialForm from "../../../forms/TestimonialForm";
import { helpers } from "../../../../../services/_helpers/Helpers";

interface IProps { };

const Testimonials: FC<IProps> = (props) => {
    const { landing, theme } = useLandingContext();
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [notFound, setNotFound] = useState<boolean>(false);
    useEffect(() => {
        async function fetchData() {
            const query = {
                filter: {
                    "site._id": landing._id,
                    publish: true
                },
                simple: 1
            };
            const result = await api.cms.get("comments", query);
            (result) ? setData(result) : setNotFound(true);
            (Array.isArray(result) && result.length > 0) ? setData(result) : setNotFound(true);
            setIsLoading(false);
        }
        fetchData();
    }, [])

    if (notFound || isLoading) return null;
    return (
        <React.Fragment>
            <Carousel
                {...helpers.data.defaultCarouselProps()}
                autoPlaySpeed={4000}
                autoPlay={true}
            >
                {data.map((testimonial, idx) => {
                    return (
                        <Col key={"testimonial-" + testimonial._id}>
                            <div className="review_strip">
                                <div>{testimonial.text}</div>
                                <br />
                                <div><strong>{`${testimonial.lastName} ${testimonial.firstName}`}</strong></div>
                            </div>
                        </Col>
                    )
                })}
            </Carousel>
            <Accordion className="w-100 text-center">
                <Accordion.Item eventKey="0">
                    <Accordion.Header eventKey="0" >
                        Добавить отзыв
                    </Accordion.Header>
                    <Accordion.Body>
                        <TestimonialForm />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </React.Fragment>
    )
};

export default Testimonials;