"use client";
import React, { useMemo } from "react";
import ImageGallery from "react-image-gallery";
import Utils from "../../../../utils/all";
import { useLandingContext } from "../../../../context/LandingProvider";
import useResize from "../../../../hooks/useResize";

const Gallery = (props) => {
    const {
        gallery,
        typeImage = "cover",
        typeImageIndex = 1,
        thumbnailPosition: propThumbnailPosition
    } = props;
    const { config } = useLandingContext();
    const { isMobile } = useResize();
    const images = useMemo(() => {
        const imgUrl = config.apiHostCms.Host;

        return gallery.map((image) => {
            const imgWithoutExtension = image.path.split(".").slice(0, -1).join(".");

            const imageWebPSrc = imgUrl + imgWithoutExtension + ".webp";

            const meta = !Utils.isEmpty(image.meta) ? image.meta : false;

            return {
                description: meta?.title || "",
                original: imageWebPSrc,
                thumbnail: imageWebPSrc,
                fullscreen: imageWebPSrc,
                useBrowserFullscreen: false,
            };
        });
    }, [gallery, typeImage, config.apiHostCms.Host]);

    const thumbnailPosition = useMemo(() => {
        return isMobile
            ? "bottom"
            : propThumbnailPosition || "right";
    }, [isMobile, propThumbnailPosition]);

    return (
        <ImageGallery
            items={images}
            lazyLoad
            thumbnailPosition={thumbnailPosition}
        />
    );
};

export default Gallery;