"use client"
import React from "react";
import { ReactSVG } from 'react-svg';
import classes from "./ListIcon.module.css";
import { helpers } from "../../../../services/_helpers/Helpers";
import { useLandingContext } from "../../../../context/LandingProvider";
import useResize from "../../../../hooks/useResize";

function ListIcon(props) {
    const { border, title, content, icon } = props;
    const { landing } = useLandingContext();
    const { isMobile } = useResize();
    const styleBorder = {
        borderColor: border ? border : "#fcb100"
    }
    const src = `${helpers.constant.cms.host}${icon.path}`;
    return (
        <div className="d-flex flex-wrap justify-content-center align-items-center mb-4">
            <div className="d-flex justify-content-center align-items-center w-100">
                <div className={classes.boxIcon} style={styleBorder}>
                    {icon && <img
                        className={classes.icon}
                        src={src}
                    />}
                </div>
            </div>
            <div>
                {title && <p className={classes.title}>{title}</p>}
                {content && <p className={classes.description}>{content}</p>}
            </div>
        </div>
    )
    if (!isMobile) {
        return (
            <div className={classes.box}>
                <div className={classes.boxIcon} style={styleBorder}>
                    {icon ? <img
                        className={classes.icon}
                        src={src}
                    /> : null}
                </div>
                {title ? <h6 className={classes.title}>{title}</h6> : null}
                {content ? <p className="description">{content}</p> : null}
            </div>
        );
    } else {
        return (
            <div className={`${classes.boxMobile}`}>
                <div className={`${classes.boxIconMobile} px-2`} style={styleBorder}>
                    <img
                        className={classes.iconMobile}
                        src={src}
                    //src={`https://${domain.url}/icons/fontAwesome/check-circle.svg`}
                    />
                </div>
                {title ? <h6 className={classes.titleMobile}>{title}</h6> : null}
                {content ? <p className="description">{content}</p> : null}
            </div>
        );
    }
}
// class ListIcon extends React.Component {
//     constructor(props) {
//         super(props);
//     }
//     render() {
//         const { config, border, title, content, icon} = this.props;
//         const styleBorder = {
//             borderColor: border ? border : "#fcb100"
//         }
//         return (
//             <div className={classes.box}>
//                 <div className={classes.boxIcon} style={styleBorder}>
//                 {icon ? <ReactSVG className={classes.icon} src={config.imageHostNoStorage+icon.path} />: null}
//                 </div>
//                 {title ? <h6 className={classes.title}>{title}</h6> : null}
//                 {content ? <p className="description">{content}</p> : null }
//             </div>
//         );
//     }
// }

export default ListIcon;