//@ts-nocheck
"use client"
import React, { FC, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { useLandingContext } from "../../../../../context/LandingProvider";
import { api } from "../../../../../services/api/Api";
import classes from "./Hotels.module.css";
import { helpers } from "../../../../../services/_helpers/Helpers";
import { ImageSrc } from "../../../../../componentsNew/elements/ImageSrc";

interface IProps { };

const Hotels: FC<IProps> = (props) => {
    const { theme } = useLandingContext();
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [notFound, setNotFound] = useState<boolean>(false);
    useEffect(() => {
        async function fetchData() {
            const { hotels } = theme;
            if (Array.isArray(hotels) && hotels.length > 0) {
                let ids = [];
                await hotels.map((hotel, idx) => {
                    ids.push(hotel._id);
                });
                const query = {
                    filter: {
                        _id: { $in: ids }
                    },
                    simple: 1
                };
                const result = await api.cms.get("hotels", query);
                (Array.isArray(result) && result.length > 0) ? setData(result) : setNotFound(true);
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);

    if (notFound || isLoading) return null;
    return (
        <Container className="mt-5">
            {data.map((item, index) => {
                return (
                    <Row className={`${classes.hotelBox} mb-4`} key={`hotelBox-${item._id}`}>
                        <Col md={12} sm={12} xs={12} className="mb-2">
                            {item.title && <h2>{item.title}</h2>}
                            <Row>
                                <Col md={5} sm={5} xs={12}>
                                    <Carousel
                                        {...helpers.data.defaultCarouselProps()}
                                    >
                                        {item.gallery && item.gallery.map((image, idx) => {
                                            return (
                                                <Col xs={12} md={12} key={`hotel_image-${idx}`} className="px-0">
                                                    <ImageSrc asset={image} cdn={false} />
                                                    {item.price && <div className={classes.priceBox}><span><strong>{item.price}</strong></span></div>}
                                                    {item.citys && <div className={`${classes.cityBox} col-12 text-left`}><span className={classes.iconCity}></span><span className={classes.cityTitle}>{item.citys.display}</span></div>}
                                                </Col>
                                            )
                                        })}
                                    </Carousel>
                                </Col>
                                <Col md={7} sm={7} xs={12}>
                                    {item.address &&
                                        <Row className="my-2">
                                            <Col xs={12} className="d-flex">
                                                <span className={`${classes.icon} mr-2`}>
                                                    <i className={classes.iconHome}></i>
                                                </span>
                                                <p><span className={classes.label}>Адрес:</span> {item.address}</p>
                                            </Col>
                                        </Row>
                                    }
                                    {item.worckRange &&
                                        <Row className="my-2">
                                            <Col xs={12} className="d-flex">
                                                <span className={`${classes.icon} mr-2`}>
                                                    <i className={classes.iconTime}></i>
                                                </span>
                                                <p><span className={classes.label}>Период работы:</span> {item.worckRange}</p>
                                            </Col>
                                        </Row>
                                    }
                                    {item.food &&
                                        <Row className="my-2">
                                            <Col xs={12} className="d-flex">
                                                <span className={`${classes.icon} mr-2`}>
                                                    <i className={classes.iconFood}></i>
                                                </span>
                                                <p><span className={classes.label}>Питание:</span> {item.food}</p>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                            {item.content && <Row>
                                <Col md={12} sm={12} xs={12}>
                                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                </Col>
                            </Row>
                            }
                            {item.web &&
                                <div className="row my-2">
                                    <div className="col-12 d-flex">
                                        <a href={item.web} className="btn btn-info" target="_blank">ПОДРОБНЕЕ</a>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                )
            })}
        </Container>
    )
};

export default Hotels;